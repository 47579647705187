import React, {Component} from 'react';
import {Alert, Button, Modal} from 'react-bootstrap';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import {faCaretSquareDown, faCaretSquareRight, faEnvelope} from '@fortawesome/free-solid-svg-icons';

import ComposanteSemestre from './ComposanteSemestre';

import ReactTooltip from 'react-tooltip';
import {BallBeat} from 'react-pure-loaders';

import PropTypes from 'prop-types';
import SessionToken from './../SessionToken';


export default class Programme extends Component {

    constructor(props) {
        super(props);
        this.state = {
            modalInvitation: false,
            modalDataInvitations: "<h3>Invitations</h3>\n",
            modalInvitationConfirm: false,
            invitation: 'INVIT',
            typeInvitation: null,
            programmeTree: null,
            refreshList: [],
            ignored: false,
        };

        // Handler
        this.handleProgrammeClick = this.handleProgrammeClick.bind(this);
        this.handleAskInvitationMassive = this.handleAskInvitationMassive.bind(this);
        this.handleInvitationMassive = this.handleInvitationMassive.bind(this);
        this.handleCloseModal = this.handleCloseModal.bind(this);
        this.handleIgnoreClick = this.handleIgnoreClick.bind(this);
    }

    // ~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~
    // Récupération au besoin des programmes associés à la Mention encours
    // ~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~
    fetchProgrammeTree() {
        // Récupération du Header à utiliser
        let apiHttpOptions = SessionToken.getApiHttpOptions();
        let payload = SessionToken.readPayload();
        let programmeTreeServiceString = "";
        if ((payload.roles.indexOf('dlg') > 0 || payload.roles.indexOf('ens') > 0)
            && payload.roles.indexOf('api_admin') === -1
            && payload.roles.indexOf('rsp') === -1) {
            programmeTreeServiceString = '/rest/private/edition/programme-ui-tree-enseignant?programme=';
        } else {
            programmeTreeServiceString = '/rest/private/edition/programme-ui-tree?programme=';
        }
        // Récupération des données
        if (apiHttpOptions) {
            // Récupération des données
            fetch(programmeTreeServiceString + this.props.programme, apiHttpOptions)
                .then((response) => {
                    if (!response.ok) {
                        console.debug("Programme['" + this.props.programme + "'].tree -> error(" + response.status + ") :: '" + response.statusText + "'");
                        throw Error(response.statusText);
                    }
                    return response;
                })
                .then((response) => {
                    return response.json()
                })
                .then((data) => {
                    console.debug("Programme['" + this.props.programme + "'].tree -> succes");
                    // rafraichir les elements
                    this.setState({programmeTree: data, invitation: data.permissionInvitationProgramme, ignored: data.ignored});
                })
                .catch((error) => {
                    console.debug("Programme['" + this.props.programme + "'].tree -> error");
                    console.debug(error);
                });
        }
    }


    // Chargement des sous-elements du programme si EXPAND
    componentDidMount() {
        if (this.props.expand) {
            this.fetchProgrammeTree();
        } else {
            this.setState({invitation: false, programmeTree: null});
        }
    }

    componentWillReceiveProps(props) {
        if (props.refreshList !== this.props.refreshList) {
            this.setState({refreshList: props.refreshList});
        }
    }

    // HANDLERS =================================================================================

    // Click on "Programme" (expand/collapse)
    handleProgrammeClick(event) {
        if (this.props.expand) {
            this.props.handleCollapseProgramme(this.props.programme);
            this.setState({invitation: false, programmeTree: null});
        } else {
            this.props.handleExpandProgramme(this.props.programme);
            this.fetchProgrammeTree();
        }
    }

    handleIgnoreClick(event) {
        if(this.state.ignored === true){
            this.setState({ignored: false});
            this.handleIgnoreProgramme(false);
        } else {
            this.setState({ignored: true});
            this.handleIgnoreProgramme(true);
        }


    }

    handleIgnoreProgramme(ignored) {
        let payload = SessionToken.readPayload();
        if (payload && payload.isConnected) {
            let apiHttpOptions = SessionToken.getApiHttpOptions();
            fetch('/rest/private/edition/ignoreParcours?programme=' + this.props.programme+ '&ignored='+ ignored, apiHttpOptions)
                .then((response) => {
                    if (!response.ok) {
                        throw Error(response.statusText);
                    }
                    return response;
                })
                .then((response) => {
                    return response.json()
                })
                .then((data) => {
                    // nouveau state pour le MODAL
                    this.setState({ignored: ignored});

                    // ~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~
                })
                .catch((error) => {
                    console.debug(error);
                });
        }

    }

    handleAskInvitationMassive(event, type) {
        console.debug("Ask Programme[programme='" + event + "'].invitations");
        if (this.state.invitation) {
            if (this.props.expand && this.state.programmeTree) {
                let payload = SessionToken.readPayload();
                if (payload && payload.isConnected) {
                    this.setState({modalInvitation: true, modalInvitationConfirm: false, modalDataInvitations: null, typeInvitation: type});
                }
            }
        }
    }

    // Invitation massive "Programme"
    handleInvitationMassive(event) {
        console.debug("Programme[programme='" + event + "'].invitations");
        // lancement de l'API
        let payload = SessionToken.readPayload();
        if (payload && payload.isConnected) {
            this.setState({modalInvitation: true, modalInvitationConfirm: true, modalDataInvitations: null});

            // ~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~
            // lancement de l'appel
            let apiHttpOptions = SessionToken.getApiHttpOptions();
            fetch('/rest/private/edition/invitations?programme=' + this.props.programme, apiHttpOptions)
                .then((response) => {
                    if (!response.ok) {
                        console.log("Programme['" + this.props.programme + "'].invitations -> error(" + response.status + ") :: '" + response.statusText + "'");
                        throw Error(response.statusText);
                    }
                    return response;
                })
                .then((response) => {
                    return response.json()
                })
                .then((data) => {
                    // ~~~ APPEL REUSSI ~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~
                    console.debug("Programme['" + this.props.programme + "'].invitations -> success");
                    // nouveau state pour le MODAL
                    this.setState({modalInvitation: true, modalInvitationConfirm: true, modalDataInvitations: data, refreshList: data.invites});

                    // ~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~
                })
                .catch((error) => {
                    console.log("Programme['" + this.props.programme + "'].invitations -> error");
                    console.debug(error);
                });
            // ~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~
        }
    }

    handleCloseModal() {
        console.debug("Programme['" + this.props.programme + "'].close ...");
        this.setState({modalInvitation: false, modalInvitationConfirm: false, modalDataInvitations: null});
    }

// RENDERS ==================================================================================
    renderModalInvitation() {
        const MODAL_TITRE = {
            INVIT: "Invitations",
            RELANCE: "Relances"
        }

        const MODAL_QUESTION = {
            INVIT: "Voulez-vous vraiment envoyer une invitation aux enseignants dont le statut est \"à faire\" ?",
            RELANCE: "Voulez-vous vraiment envoyer une relance aux enseignants dont les cours ne sont pas validés ?"
        }

        if (this.state.modalInvitation) {
            return (
                <Modal show={true} aria-labelledby="contained-modal-title-vcenter" size='lg' onHide={() => this.handleCloseModal()}>
                    <Modal.Header closeButton>
                        <Modal.Title>{this.props.libelle}</Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                        <h3>{MODAL_TITRE[this.state.typeInvitation]}</h3><br/>
                        {this.state.modalInvitationConfirm ? "" : <span>{MODAL_QUESTION[this.state.typeInvitation]}</span>}
                        {this.state.modalDataInvitations ?
                            <div>
                                {this.state.modalDataInvitations.noguest && this.state.modalDataInvitations.noguest.length > 0 ?
                                    <div>
                                        <p><span className="alert alert-warning">Cours sans enseignant désigné</span></p>
                                        <ul>
                                            {this.state.modalDataInvitations.noguest.map((obj) => <li key={obj.ens_ide}>{obj.ens_int} ({obj.ens_ide})</li>)}
                                        </ul>
                                    </div>
                                    :
                                    ""
                                }
                                {this.state.modalDataInvitations.nomail && this.state.modalDataInvitations.nomail.length > 0 ?
                                    <div>
                                        <p><span className="alert alert-warning">Cours avec enseignant sans mail</span></p>
                                        <ul>
                                            {this.state.modalDataInvitations.nomail.map((obj) => <li>{obj.ens_int} ({obj.ens_ide})</li>)}
                                        </ul>
                                    </div>
                                    :
                                    ""
                                }
                                {this.state.modalDataInvitations.invites && this.state.modalDataInvitations.invites.length > 0 ?
                                    <div>
                                        <p><span className="alert alert-success">Cours avec enseignant invité</span></p>
                                        <ul>
                                            {this.state.modalDataInvitations.invites.map((obj) => <li>{obj.ens_int} ({obj.ens_ide})</li>)}
                                        </ul>
                                    </div>
                                    :
                                    ""
                                }
                                {this.state.modalDataInvitations.ignores && this.state.modalDataInvitations.ignores.length > 0 ?
                                    <div>
                                        <p><span className="alert alert-dark">Cours ignoré</span></p>
                                        <ul>
                                            {this.state.modalDataInvitations.ignores.map((obj) => <li>{obj.ens_int} ({obj.ens_ide})</li>)}
                                        </ul>
                                    </div>
                                    :
                                    ""
                                }
                            </div>
                            :
                            <div>
                                {this.state.modalInvitationConfirm ? <BallBeat color={'#123abc'} loading={true}/> : ""}
                            </div>
                        }
                    </Modal.Body>
                    <Modal.Footer>
                        {this.state.modalInvitationConfirm ? "" : <Button variant="primary" onClick={() => this.handleInvitationMassive(this.props.programme)}>Confirmer</Button>}
                        <Button variant="secondary" onClick={() => this.handleCloseModal()}>{this.state.modalInvitationConfirm ? "Fermer" : "Annuler"}</Button>
                    </Modal.Footer>
                </Modal>
            );
        } else {
            return '';
        }
    }

    render() {
        let modalInvitationHtml = this.renderModalInvitation();

        // permission pour faire des invitations en masse sur un programme
        let invitation = this.state && this.state.invitation;

        // y a t'il des composantes dans le explode du programme
        let hasComposantes = false;
        if (this.props.expand && this.state.programmeTree && this.state.programmeTree.composantes.length > 0)
            hasComposantes = true;

        // realisation du rendu-programme si explode
        let listComposanteSemestre = '';
        if (this.props.expand && this.state.programmeTree) {
            if (this.state.programmeTree.composantes.length > 0) {
                listComposanteSemestre = this.state.programmeTree.composantes.map((itemComposante) =>
                    itemComposante.semestres.map((itemSemestre) =>
                        <ComposanteSemestre key={'Prog_' + this.props.programme + '-CompoSemestre_' + itemComposante.code + '-' + itemSemestre.code}
                                            annee={this.props.annee}
                                            mention={this.props.mention}
                                            programme={this.props.programme}
                                            composante={itemComposante.code}
                                            semestre={itemSemestre.code}
                                            libelle={itemSemestre.libelle + ' > ' + itemComposante.libelle}
                                            enseignements={itemSemestre.enseignements}
                                            lib_mention={this.props.lib_mention}
                                            lib_programme={this.props.libelle}
                                            refreshList={this.state.refreshList}
                        />
                    )
                );
            } else {
                // ========================================
                // CAS D'ERREUR
                //    REQUETE : BIG_LINE erreur
                //
                // formation qui ne respecte pas l'arborescence ROF defini par Fred en 2016
                // ========================================

                listComposanteSemestre = (
                    <div>
                        <Alert variant="warning">Arborescence non respectée : composante/semestre</Alert>
                    </div>
                );
            }
        }

        return (
            <div className="mention" key={'div-prg-' + this.props.programme}>
                {modalInvitationHtml}
                <div className="bloc-programme" key={this.props.programme}>
                        <span className="cursor-pointer" onClick={() => this.handleProgrammeClick(this.props.programme)}>
                            {this.props.expand ? <FontAwesomeIcon icon={faCaretSquareDown}/> : <FontAwesomeIcon icon={faCaretSquareRight}/>}
                            {' '}{this.props.libelle}
                        </span>

                    {(invitation && this.props.expand && hasComposantes > 0) ?
                        <span>
                            <span className="cursor-pointer float-right marginleft6" data-tip data-for='exclusion-parcours'>
                                <input type="checkbox" checked={this.state.ignored} name="nom" onChange={() => this.handleIgnoreClick(this.state.ignored)}/>
                            </span>
                            <ReactTooltip id='exclusion-parcours' type='warning'>
                                <span>Parcours à exclure de l’envoi du mail de l’invitation générale</span>
                            </ReactTooltip>
                        </span>
                        : ""
                    }

                    {(invitation && this.props.expand && hasComposantes > 0) ?
                        <span className="float-right margintopN6">
                                <Button size="sm" data-tip data-for='reinvitation-programme' className="btn btn-ws btn-light btn-lmargin" onClick={() => this.handleAskInvitationMassive(this.props.programme, 'RELANCE')}>
                                    <FontAwesomeIcon icon={faEnvelope}/> Relance
                                </Button>
                                <ReactTooltip id='reinvitation-programme' type='warning'>
                                    <span>Invitations aux cours non-validés du programme</span>
                                </ReactTooltip>
                            </span>
                        : ""
                    }
                    {(invitation && this.props.expand && hasComposantes > 0 && false) ?
                        <span className="float-right margintopN6">
                                <Button size="sm" data-tip data-for='invitation-programme' className="btn btn-ws btn-light btn-lmargin" onClick={() => this.handleAskInvitationMassive(this.props.programme, 'INVIT')}>
                                    <FontAwesomeIcon icon={faEnvelope}/> Invitation
                                </Button>
                                <ReactTooltip id='invitation-programme' type='warning'>
                                    <span>Invitations aux cours avec le statut "à faire"</span>
                                </ReactTooltip>
                            </span>
                        : ""
                    }

                </div>
                {this.props.expand ? listComposanteSemestre : ""}
            </div>
        );
    }
}

// =================================
// Propriété attendu du composant
// =================================
Programme.propTypes = {
    annee: PropTypes.string,
    mention: PropTypes.string,
    lib_mention: PropTypes.string,
    programme: PropTypes.string,
    libelle: PropTypes.string,
    expand: PropTypes.bool,
    ignored: PropTypes.bool,
    handleExpandMention: PropTypes.func,
    handleCollapseMention: PropTypes.func,
};
