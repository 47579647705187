import React, { Component } from 'react';
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';



export default class Footer extends Component {

    constructor(props) {
        super(props);
        this.state = {
            versionWS: '0.1',
            contact: 'mysyllabus@dauphine.psl.eu'
        }
    }

    componentWillMount() {
        fetch('/rest/public/version')
            .then((response) => {
                return response.text();
            })
            .then((data) => {
                console.debug(data);
                this.setState({ versionWS: data });
            })

        fetch('/rest/public/edition/contact')
            .then((response) => {
                return response.text();
            })
            .then((data) => {
                console.debug(data);
                this.setState({ contact: data });
            })
    }

    render() {
        return (
            <footer>
                <Container fluid className="container-header-bg">
                    <Row>
                        <Col xs lg="4">© 2020 <a href="http://www.dauphine.fr">Université Paris Dauphine - PSL</a></Col>
                        <Col xs lg="4" className="text-center">MySyllabus - <small>{this.state.versionWS}</small></Col>
                        <Col xs lg="4" className="text-right">Contact: <a href={"mailto:" + this.state.contact + '?subject=Merci de préciser en objet à quel département s’adresse votre demande'}>{this.state.contact}</a></Col>
                    </Row>
                </Container>
            </footer>
        );
    }
}
