import React from 'react';

import {Table, Button, Alert, Card, Modal} from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import ReactTooltip from 'react-tooltip';
import { faCheck, faEye, faPencilAlt, faTimes, faPenSquare } from '@fortawesome/free-solid-svg-icons';
import { FaQuestionCircle } from 'react-icons/fa';
import RichTextEditor from 'react-rte';
import SessionToken from "../SessionToken";

export default class BodyEnseignement extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            ues: {},
            showOk: false,
            showMandatories: false
        }

        this.references = {
            langues: {},
            niveau_langues: {},
            etats: {
                0: 'A faire',
                1: 'Invité',
                2: 'Modifié',
                3: 'Validé',
                4: 'Confirmé'
            },
            mandatory: [
                "lan_cod", "ens_obj_pub", "ens_des_pub"
            ],
            enseignement_ui: {}
        };
        this.isDelegataireExterne = false;

        this.getDataEnseignement = this.getDataEnseignement.bind(this);
        this.handleRTEChanges = this.handleRTEChanges.bind(this);
    }

    componentWillMount() {
        var myHeaders = new Headers();
        let apptoken = sessionStorage.getItem('apptoken');
        myHeaders.append("jwt-dauphine", apptoken);
        var myOptions = {
            methode: 'GET',
            headers: myHeaders,
            mode: 'cors',
            cache: 'default'
        };

        // Affichage dynamique des champs
        fetch('/rest/private/edition/preview', myOptions)
            .then((response) => {
                return response.json();
            })
            .then((data) => {
                this.setState({ preview: data });
            });

        // récupération des informations sur les niveaux de lanques
        fetch('/rest/private/edition/niveauxLangues', myOptions)
            .then((response) => {
                if (!response.ok) {
                    throw Error(response.statusText);
                }
                return response;
            })
            .then((response) => response.json())
            .then((data) => {
                console.debug("nvxLng:", data);
                let niveau_langues = { 'NA': 'Non Applicable' };

                data.forEach((item) => {
                    niveau_langues[item.nel_cod] = item.nel_lib;
                })

                console.debug(niveau_langues);
                this.references['niveau_langues'] = niveau_langues;
            })
            .catch((error) => {
                console.debug(error);
            });

        // récupération des informations sur les lanques
        fetch('/rest/private/edition/langues', myOptions)
            .then((response) => {
                if (!response.ok) {
                    throw Error(response.statusText);
                }
                return response;
            })
            .then((response) => response.json())
            .then((data) => {
                // console.debug("Lng:", data);
                if( data.length > 0 ) {
                    let langues = {};
                    data.forEach((item) => {
                            langues[item.code] = item.libelleLong;
                    })
    
                    // console.debug(langues);
                    this.references['langues'] = langues;
                }
            })
            .catch((error) => {
                console.debug(error);
            });

        fetch('/rest/private/edition/enseignement-ui?enseignement=' + this.props.edit_id, myOptions)
            .then((response) => {
                return response.json();
            })
            .then((data) => {
                this.references['enseignement_ui'] = data;
                if (this.references['enseignement_ui'].delegataire && this.references['enseignement_ui'].delegataire.nom === 'Contact externe') {
                    this.isDelegataireExterne = true;
                }
            });
        fetch('/rest/private/aides/UE', SessionToken.getApiHttpOptions())
            .then((response) => {
                return response.json();
            })
            .then((data) => {
                this.setState({aide: data.contenu});
            });
    }

    applyTemplate(object, id) {

        let template = object.htmlTemplate;
        if (object.fieldReferences === 'enseignement_ui' && !this.references[object.fieldReferences].enseignant) {
            template = "{{ value }}";
        }
        if (template === "") {
            template = "{{ title }}{{ value }}"
            template = template.replace("{{ title }}", "<p class='title_field'>" + object.htmlTitle + "</p>");
        } else {
            template = template.replace("{{ title }}", object.htmlTitle);
        }

        // debug.debug(object);

        // Si une valeur est renseignée pour ce champs
        let value = this.state.ues[id][object.fieldName];
        if (object.fieldName === 'ind_adr_int' && this.state.ues[id][object.fieldName] === undefined) {
            if (this.references['enseignement_ui'].delegataire && this.references['enseignement_ui'].delegataire.adr_int) value = this.references['enseignement_ui'].delegataire.adr_int;
            else if (this.references['enseignement_ui'].enseignant && this.references['enseignement_ui'].enseignant.adr_int) value = this.references['enseignement_ui'].enseignant.adr_int;
        }
        if (value && typeof value === 'string') value = value.trim();
        if (value || object.fieldName==="null") {
            // <<<<<<<<<<<<<<<<<<<<<<<<< AVEC VALEUR >>>>>>>>>>>>>>>>>>>>>>>>>>>>
            
            // Si la valeur est une référence à une table :
            if (object.fieldReferences) {
                console.debug("Index dans la table de ref:", this.state.ues[id][object.fieldName]);
                console.debug("Table de référence:", this.references[object.fieldReferences]);
                
                // on remplace la valeur par celle contenue dans la table de référence
                //console.debug("~~ FIELD['"+object.fieldName+"'] -> display-with-refkey ~~");
                if (object.fieldReferences === 'enseignement_ui') {
                    if (this.references[object.fieldReferences].enseignant)
                        template = template.replace("{{ value }}", this.references[object.fieldReferences].enseignant.prenom + ' ' + this.references[object.fieldReferences].enseignant.nom);
                    else template = template.replace("{{ value }}", "");
                } else {
                    template = template.replace("{{ value }}", this.references[object.fieldReferences][this.state.ues[id][object.fieldName]]);
                }
            } else {
                // On remplace la valeur par celle contenue dans le state
                console.debug("~~ FIELD['"+object.fieldName+"'] -> display-with-valeur ~~");
                template = template.replace("{{ value }}", value);
            }
        } else if (this.state.modifyEdit) {
            template = template.replace("{{ value }}", "");
        } else {
            template="";
        }
        return template;
    }

    handleEditClick(event) {
        let n_state = { edit_field: event };
        n_state["rteValue"] = RichTextEditor.createEmptyValue();

        let res = this.state.preview.fields.filter((obj) => {
            return obj.fieldName === event;
        })

        if (res[0].htmlFieldType === "bloc") {
            let value = this.state.ues[this.props.edit_id][event];
            if (event === 'ind_adr_int' && value === undefined) {
                if (this.references['enseignement_ui'].delegataire && this.references['enseignement_ui'].delegataire.adr_int) value = this.references['enseignement_ui'].delegataire.adr_int;
                else if (this.references['enseignement_ui'].enseignant && this.references['enseignement_ui'].enseignant.adr_int) value = this.references['enseignement_ui'].enseignant.adr_int;
            }
            n_state["rteValue"] = RichTextEditor.createValueFromString(value, 'html');
        }
        this.setState(n_state);
    }

    // =========================================================
    // validation de modification de champs
    // =========================================================
    handleModifyField(id, field, value) {
        if (!document.getElementById('form_' + field).checkValidity()) {
            return;
        }
        console.debug("handleModifyField -> fetch");
        console.debug("value:", value);

        // Gestion du retour de RTE vide
        let url = '/rest/private/edition/editField?enseignement=' + id + '&name=' + field
        let field_type = this.state.preview.fields.filter( (obj) => { 
            if (obj.fieldName === field) {
                return obj 
            } else {
                return null;
            }
        });
        console.debug(field_type);
        field_type = field_type[0].htmlFieldType;
        console.debug(field, '=>', field_type);

        switch (field_type) {
            case 'bloc':
                console.debug('This is a RTE return');
                if ( (value.replace("\n","") === '<p><br></p>') || (value.replace("\n","") === '<p><br/></p>') ) {
                    console.debug("Empty RTE return");
                    value = "''";
                } else {
                    url += '&value=' + encodeURIComponent(value);
                }
                break;
            case "select":
                console.debug('This is a select return');
                if (value === 'NA') {
                    console.debug("Empty select return");
                } else {
                    url += '&value=' + encodeURIComponent(value);
                }
                break;
            default:
                url += '&value=' + encodeURIComponent(value);
        }

        console.debug("value:", value);
        console.debug("fetch:", url);

        // Fetch the data
        var myHeaders = new Headers();
        let apptoken = sessionStorage.getItem('apptoken');
        myHeaders.append("jwt-dauphine", apptoken);
        var myOptions = {
            methode: 'GET',
            headers: myHeaders,
            mode: 'cors',
            cache: 'default'
        };

        // Récupération des données
        // TODO : catch errors...
        console.debug("TODO: catch errors");
        fetch(url, myOptions).then((response) => response.json()).then((data) => {
            let ues = this.state.ues;
            ues[id][data["fieldName"]] = data.fieldValue;
            this.setState({ ues: ues, edit_field: '' });
        });

    }

    // =================================================
    // bouton validation définitive
    // =================================================
    validateEditUE(id) {
        let url = '/rest/private/edition/validate?enseignement=' + id;

        var errors = []
        this.references.mandatory.forEach(
            (field) => {
                console.debug(this.state.ues[id][field]);
                if( !this.state.ues[id][field] || this.state.ues[id][field] === "" || this.state.ues[id][field] === 0 ) {
                    errors.push(field)
                } else {
                    console.debug("field", field, "OK");
                }
            }
        );
        console.debug(errors);
        if( errors.length > 0 ) {
            //this.setState({ showMandatories: true})
            this.setState({showOk: false, showMandatories: true})
            return;
        }

        if (window.confirm("Vous allez confirmer les contenus de cet enseignement. Une fois validés, vous ne pourrez plus les modifier.")) {
            // Fetch the data
            var myHeaders = new Headers();
            let apptoken = sessionStorage.getItem('apptoken');
            myHeaders.append("jwt-dauphine", apptoken);
            var myOptions = {
                methode: 'GET',
                headers: myHeaders,
                mode: 'cors',
                cache: 'default'
            };

            // Récupération des données
            console.debug("TODO: catch errors");
            fetch(url, myOptions).then((response) => response.json()).then((data) => {
                let ues = this.state.ues;
                ues[id][data["fieldName"]] = data.fieldValue;
                //this.setState({ ues: ues, edit_field: '', showOk: true });
                this.setState({ ues: ues, edit_field: '', showOk: true, showMandatories: false });
            });
            this.setState({modifyEdit: false});
        }
    }

    getDataEnseignement(id) {
        let ues = this.state.ues;
        let url = '/rest/private/edition/enseignement?code=' + id;
        console.debug("fetch enseignement data: " + url);

        // Fetch the data
        var myHeaders = new Headers();
        let apptoken = sessionStorage.getItem('apptoken');
        myHeaders.append("jwt-dauphine", apptoken);
        var myOptions = {
            methode: 'GET',
            headers: myHeaders,
            mode: 'cors',
            cache: 'default'
        };

        // Récupération des données
        fetch(url, myOptions)
            .then(response => response.json())
            .then((data) => {
                console.log("data :" + data);
                let old_show = "";
                let old_edit = "";
                if (ues[id]) {
                    old_show = ues[id].show;
                    old_edit = ues[id].edit;
                }
                console.log("ues :" + ues[id]);
                ues[id] = data.s2EnseignementV;
                ues[id].show = old_show;
                ues[id].edit = old_edit;

                data.s2SaisieField.forEach((value) => {
                    ues[id][value.fieldName] = value.fieldValue
                });

                // Get the state :
                // On affiche l'alerte si le cours est validé ou plus et si la personne n'a pas le droit de modifier dans cet état.
                let showOk = false;
                let showMandatories =this.state.showMandatories;
                if( data.s2SessionEdition ) {
                    console.debug('~~~ state Edition: ' + data.s2SessionEdition.etat);
                
                    if( data.s2SessionEdition.etat > 3 && !this.props.overrideShowOk ) {
                        showOk = true;
                        showMandatories = false;
                    }
                }

                this.setState({ ues: ues, showOk: showOk , showMandatories: showMandatories });
            });

    }

    getUEHTML(id) {
        let html = [];
        if (this.state.preview) {
            this.state.preview.fields.forEach((value, index) => {
                if (value.fieldName !== 'ind_adr_int' || !this.isDelegataireExterne) {
                    html.push(<p key={"show" + index} dangerouslySetInnerHTML={{ __html: this.applyTemplate(value, id) }}></p>)
                }
            });
        }
        return html;
    }

    modifyEditUE(id) {
        let state = !this.state.modifyEdit;
        this.setState({ modifyEdit: state, edit_field: "" });
    }

    handleRTEChanges(rteValue) {
        this.setState({ rteValue });
    }

    getEditUEHTML(id) {
        let html = [];
        let inner = [];
        html.push(<br key="someBR" />);

        this.state.preview.fields.forEach((value) => {
            if (value.htmlEditable) {
                if (this.state.edit_field === value.fieldName) {
                    // Mode edit
                    let input = [];
                    if (value.htmlFieldType === "select") {
                        if (value.fieldName === "lan_cod") {
                            let inner = [];
                            Object.keys(this.references.langues).forEach( (key) => {
                                inner.push(<option value={key}>{this.references.langues[key]}</option>);
                            })
                            input = (
                                <select className="form-control col-sm-4" name="langue" ref={(input) => this.menu = input} defaultValue={this.state.ues[id][value.fieldName]}>
                                    {inner}
                                </select>
                            );
                        } else {
                            let inner = [];
                            Object.keys(this.references.niveau_langues).forEach((key) => {
                                inner.push(<option value={key}>{this.references.niveau_langues[key]}</option>);
                            });
                            input = (
                                <select className="form-control col-sm-4 " name="langue_lvl" ref={(input) => this.menu = input} defaultValue={this.state.ues[id][value.fieldName]}>
                                    {inner}
                                </select>
                            );
                        }
                    } else if (value.htmlFieldType === "bloc") {
                        // Display Rich Text Editor... todo
                        //     ATTENTION : 'placeholder' ne fonctionne pas pour l'instant
                        //        voir s'il y a pas un sous-module dans RichTextEditor à placer avec placeholder

                        if ( this.state.rteValue!==null )
                            console.debug("RTE value-type : '",this.state.rteValue,"'");

                        let isRteEmpty =true;
                        if ( this.state.rteValue && this.state.rteValue!==null ){
                            // convertir RTE-STATUS en chaine-string
                            let stringRte       =this.state.rteValue.toString('html');
                            // enlever les espacements HTML
                            let stringSimplifie =stringRte.replace(/ /g,'').replace(/\n/g,'').replace(/<p>/g,'').replace(/<br\/>/g,'').replace(/<\/p>/g,'');
                            if ( stringSimplifie!=='' && stringSimplifie!=='undefined' && stringSimplifie!=='A renseigner' ){
                                isRteEmpty=false;
                                //stringvalue=RichTextEditor.createValueFromString(this.state.rteValue,'html');
                            }
                        }

                        //console.debug("stringvalue 2 richtext : '",richvalue,"'");
                        if (isRteEmpty){
                            let emptyStringHtmlValue=RichTextEditor.createValueFromString('','html');
                            console.debug("RTE (empty) : '",emptyStringHtmlValue,"'");
                            input = (
                                    <RichTextEditor
                                        autoFocus
                                        placeholder="A renseigner"
                                        onChange={this.handleRTEChanges}
                                        value={emptyStringHtmlValue}
                                    >
                                    </RichTextEditor>
                                );
                        } else {
                            console.debug("RTE (value) : '",this.state.rteValue,"'");
                            input = (
                                    <RichTextEditor
                                        autoFocus
                                        placeholder="A renseigner"
                                        onChange={this.handleRTEChanges}
                                        value={this.state.rteValue}
                                    >
                                    </RichTextEditor>
                                );
                        }
                    } else if (value.htmlFieldType === "url") {
                        input = (
                            <input type="url" className="form-control" name={value.fieldName} ref={(input) => this.menu = input} defaultValue={this.state.ues[id][value.fieldName]} />
                        );
                    }

                    let button_validate = (
                        <Button
                            variant="primary"
                            size="sm"
                            onClick={() => this.handleModifyField(id, value.fieldName, this.state.rteValue.toString('html'))}
                            className='iconButton'
                        >
                            <FontAwesomeIcon icon={faCheck} size="lg" />
                        </Button>
                    )
                    if (value.fieldName === "lan_cod" || value.fieldName === "nel_cod" || value.htmlFieldType === "url") {
                        button_validate = (
                            <Button
                                size="sm"
                                variant="primary"
                                onClick={() => {this.handleModifyField(id, value.fieldName, this.menu.value)}}
                                className='iconButton'
                            >
                                <FontAwesomeIcon icon={faCheck} size="lg" />
                            </Button>
                        )
                    }


                    inner.push(<tr key={"edit_value.fieldName"}>
                        <td colSpan={2}>
                            <form id={'form_' + value.fieldName} onInvalid={(e) => e.preventDefault()}>
                            <p className="title_field">{value.htmlTitle}<span className="validationMsg_field">{value.validationMsg}</span></p>
                            {input}
                            {button_validate}{' '}
                            <Button size="sm" variant="warning" onClick={() => this.setState({ edit_field: '' })} className='iconButton'>
                                <FontAwesomeIcon icon={faTimes} size="lg" />
                            </Button>
                            </form>
                        </td>
                    </tr>);
                } else {
                    // Mode display only
                    if (value.fieldName !== 'ind_adr_int' || !this.isDelegataireExterne) {
                        inner.push(
                            <tr key={"disp_" + value.fieldName}>
                                <td>
                                    <Button size="sm" data-tip data-for={'modifier-field-'+value.fieldName} className='iconButton' onClick={() => this.handleEditClick(value.fieldName)}>
                                        <FontAwesomeIcon icon={faPenSquare} size="lg" />
                                    </Button>
                                    <ReactTooltip id={'modifier-field-'+value.fieldName} type='info'><span>Modifier</span></ReactTooltip>
                                </td>
                                <td dangerouslySetInnerHTML={{ __html: this.applyTemplate(value, id) }}></td>
                            </tr>);
                    }
                }
            }
        });

        html.push(
            <Table key="edit_table">
                <tbody>{inner}</tbody>
            </Table>);

        return html;
    }

    handleOpenModalAide() {
        this.setState({modalAide: true});
    }

    handleCloseModal() {
        this.setState({modalAide: false});
    }

    renderModalAide() {
        if (this.state.modalAide) {
            return (
                <Modal show={true} aria-labelledby="contained-modal-title-vcenter" size='lg' onHide={() => this.handleCloseModal()}>
                    <Modal.Header closeButton>
                        <Modal.Title>Aide à la rédaction : Unité d'enseignement</Modal.Title>
                    </Modal.Header>
                    <Modal.Body className="height70vh">
                        <div dangerouslySetInnerHTML={{ __html: this.state.aide }}></div>
                    </Modal.Body>
                    <Modal.Footer>
                        <Button onClick={() => this.handleCloseModal()}>Fermer</Button>
                    </Modal.Footer>
                </Modal>
            );
        } else {
            return '';
        }
    }

    render() {
        let modalAideHtml = this.renderModalAide();
        let html = [];
        let button = [];
        let id = this.props.edit_id;
        // get infos...
        let ues = this.state.ues;
        if (!ues[id]) {
            this.getDataEnseignement(id);
            return (<h2 key="loading_things">Chargement en cours ...</h2>);
        }
        if (this.state.modifyEdit) {
            html = this.getEditUEHTML(id);
            button.push(<Button key="button_editform" variant="outline-primary" onClick={() => this.modifyEditUE(id)}><FontAwesomeIcon icon={faEye} size="lg" /> Visualiser</Button>);
        } else {
            html = this.getUEHTML(id);
            button.push(<Button key="button_editform" variant="outline-primary" onClick={() => this.modifyEditUE(id)}><FontAwesomeIcon icon={faPencilAlt} size="lg" /> Modifier</Button>);
        }

        return (
            <div key="main_page">
                {modalAideHtml}
               { this.props.modal ? "" :
                <div className="title"><span>Edition d'enseignement</span></div>
               }
               { !this.props.modal&&this.props.mention ? <p><br/><a href='/'>Accueil (liste des cours)</a> &gt; { decodeURIComponent(this.props.mention)} &gt; { decodeURIComponent(this.props.programme) } &gt; { decodeURIComponent(this.props.semestre) } &gt; {this.state.ues[id].ens_int}</p> : "" }
               <Card className={this.props.modal ? 'modal-card-dauphine' : 'card-dauphine'} key="edit_div" id="edit_div">
                    { this.props.modal ? "" :
                    <Card.Header as="h5">
                        <span>{this.state.ues[id].ens_int}</span> <span className="smallFont">({this.state.ues[id].ens_ide})</span>
                        <span className="float-right"><a className="awhite" href={""} onClick={(e) => {e.preventDefault();this.handleOpenModalAide()}}><FaQuestionCircle /> Aide à la rédaction</a></span>
                    </Card.Header>
                    }
                    <Card.Body>
                        <Alert
                            key="Alert_Edit"
                            variant="success"
                            show={this.state.showOk && !this.props.modal}
                        >
                            Le descriptif de cet enseignement a été validé. Contactez le référent ROF de la formation si vous souhaitez le modifier.
                        </Alert>
                        <Alert
                            key="Alert_Confirm"
                            variant="danger"
                            show={this.state.showMandatories && !this.props.modal}
                        >
                            Le descriptif de cet enseignement ne peut être validé. Les champs "Langues du cours", "Compétences à acquérir" et "Description du contenu de l'enseignement" sont obligatoires.
                        </Alert>
                        {this.props.modal||this.state.showOk ? "" : button}{' '}
                        {this.props.modal||this.state.showOk ? "" : <Button variant="outline-danger" onClick={() => this.validateEditUE(id)}><FontAwesomeIcon icon={faCheck} size="lg" /> Validation Définitive</Button>}
                        {this.props.modal ? "" : <p></p>}
                        {html}
                    </Card.Body>
                </Card>
            </div>
        );
    }
}

