import React, {Component} from 'react';
import {Button, Modal} from 'react-bootstrap';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import {
    faCheck,
    faEdit,
    faHourglass,
    faPaperPlane,
    faPencilAlt,
    faQuestion,
    faSignInAlt,
    faUndo,
    faUpload
} from '@fortawesome/free-solid-svg-icons';
import {faFile} from '@fortawesome/fontawesome-free-regular';
import Autocomplete from 'react-autocomplete';
import ReactTooltip from 'react-tooltip';
import PropTypes from 'prop-types';
import BodyEnseignement from './../edition/BodyEnseignement';
import SessionToken from './../SessionToken';
import {debounce} from 'lodash';
import ErrorHandler from "../ErrorHandler";

export default class Enseignement extends Component {

    constructor(props) {
        super(props);
        this.state = {
            modalSessionEdition: false,
            modeEditDelegataire: false,
            selectedDelegataire: '',
            suggestedDelegataires: [],
            ens: {},
            waitButtonInvite: false,
            exportSuccessStatus: null,
            enseignantNom: '',
            modalValidation: false,
            etatremplissage: ''
        };

        // Handlers
        this.handleViewClick = this.handleViewClick.bind(this);
        this.handleCloseModal = this.handleCloseModal.bind(this);
        this.handleValidation = this.handleValidation.bind(this);
        this.handleEditDelegataireButton = this.handleEditDelegataireButton.bind(this);
        this.handleResetDelegataireButton = this.handleResetDelegataireButton.bind(this);

        // input-delegataire
        this.handleOnChangeDelegataire = (event) => {
            this.setState({selectedDelegataire: event.target.value, alertMsg: null});
            if (!this.debouncedFn) {
                this.debouncedFn = debounce(() => {
                    this.searchDelegataire()
                }, 500);
            }
            this.debouncedFn();
        };
        this.handleOnSelectDelegataire = this.handleOnSelectDelegataire.bind(this);


        this.handleInviteButton = this.handleInviteButton.bind(this);
        this.handleExportButton = this.handleExportButton.bind(this);
        this.handleEditButton = this.handleEditButton.bind(this);
        this.validateUE = this.validateUE.bind(this);

    }


    refreshEns() {
        // Récupération de la liste des enseignants
        let payload = SessionToken.readPayload();
        if (payload && payload.isConnected) {
            let apiHttpOptions = SessionToken.getApiHttpOptions();
            fetch('/rest/private/edition/enseignement-ui?enseignement=' + this.props.enseignement, apiHttpOptions)
                .then((response) => {
                    return response.json();
                })
                .then((data) => {
                    let enseignantNom = data.enseignant ? data.enseignant.nom + " " + data.enseignant.prenom : '';
                    this.setState({ens: data, waitButtonInvite: false, enseignantNom: enseignantNom});
                });
        } else {
            this.setState({ens: {}, waitButtonInvite: false});
        }
    }

    componentDidMount() {
        this.refreshEns();
    }

    componentWillReceiveProps(props) {
        if (props.refreshList !== this.props.refreshList && props.refreshList != null) {
            props.refreshList.forEach((ens) => {
                if (this.props.enseignement === ens.ens_ide)
                    this.refreshEns();
            });
        }
    }

    hasDelegataire() {
        let result = false;
        if (this.state.ens && this.state.ens.delegataire)
            result = true;
        return result;
    }

    // enseignant responsable / enseignant délégataire
    getDefaultDelegataire() {
        let delegataire = null;
        if (this.state.ens) {
            if (this.state.ens.enseignant) {
                delegataire = this.state.ens.enseignant;
            }
            if (this.state.ens.delegataire) {
                delegataire = this.state.ens.delegataire;
            }
        }
        return delegataire;
    }

    // enseignant responsable / enseignant délégataire avec tooltip pour l'affichage
    getEnseignant() {
        let result = null;
        if (this.state.ens) {
            if (this.state.ens.enseignant) {
                result = {tooltip: "Enseignant-responsable (ROF)", delegataire: this.state.ens.enseignant};
            }
            if (this.state.ens.delegataire) {
                result = {tooltip: "Enseignant-délégataire", delegataire: this.state.ens.delegataire};
            }
        }
        return result;
    }

    // ========================================================
    // HANDLERS
    // ========================================================
    handleViewClick() {
        this.setState({modalSessionEdition: true});
    }

    handleCloseModal() {
        this.setState({modalSessionEdition: false, modalValidation: false});
    }

    handleEditDelegataireButton() {
        if (this.state.ens && this.state.ens.permissions.changeDelegataire)
            this.setState({modeEditDelegataire: true});
    }

    handleResetDelegataireButton() {
        if (!this.state.modeEditDelegataire && this.hasDelegataire()) {
            // appel du reset
            let payload = SessionToken.readPayload();
            if (payload && payload.isConnected) {
                let apiHttpOptions = SessionToken.getApiHttpOptions();
                fetch('/rest/private/edition/resetDelegataire?enseignement=' + this.props.enseignement, apiHttpOptions)
                    .then((response) => {
                        if (!response.ok) {
                            console.debug("Enseignement['" + this.props.enseignement + "'].resetDelegataire -> error(" + response.status + ") :: '" + response.statusText + "'");
                            throw Error(response.statusText);
                        }
                        return response;
                    })
                    .then((response) => {
                        return response.json()
                    })
                    .then((data) => {
                        // rafraichir les elements
                        this.refreshEns();
                    })
                    .catch((error) => {
                        console.debug("Enseignement['" + this.props.enseignement + "'].resetDelegataire -> error");
                        console.debug(error);
                    });
            }
        }
    }

    handleInviteButton() {

        this.setState({waitButtonInvite: true});

        //  Call Fetch
        let payload = SessionToken.readPayload();
        if (payload && payload.isConnected) {
            let apiHttpOptions = SessionToken.getApiHttpOptions();
            fetch('/rest/private/edition/invitation?enseignement=' + this.props.enseignement, apiHttpOptions)
                .then((response) => {
                    if (!response.ok) {
                        throw Error(response.statusText);
                    }
                    return response;
                })
                .then((response) => {
                    return response.json()
                })
                .then((data) => {
                    // rafraichir les elements
                    this.refreshEns();
                })
                .catch((error) => {
                    console.debug(error);
                });
        }
    }

    handleExportButton() {
        let payload = SessionToken.readPayload();
        if (payload && payload.isConnected) {
            let apiHttpOptions = SessionToken.getApiHttpOptions();

            // APPEL avec message de confirmation
            fetch('/rest/private/edition/confirmAndExport?enseignement=' + this.props.enseignement, apiHttpOptions)
                .then((response) => {
                    if (!response.ok) {
                        console.debug("Enseignement['" + this.props.enseignement + "'].confirmAndExport -> error(" + response.status + ") :: '" + response.statusText + "'");
                        throw Error(response.statusText);
                    }
                    return response;
                })
                .then((response) => {
                    return response.json()
                })
                .then((data) => {
                    this.setState({exportSuccessStatus: "success"});
                    // rafraichir les elements
                    this.refreshEns();
                })
                .catch((error) => {
                    this.setState({exportSuccessStatus: "failed"});
                    console.debug("Enseignement['" + this.props.enseignement + "'].confirmAndExport -> error");
                    console.debug(error);
                });

            // message temporaire
            window.setTimeout(() => {
                this.setState({exportSuccessStatus: null});
            }, 2000);
        }
    }

    handleEditButton() {
        let enseignant = this.getEnseignant();
        let payload = SessionToken.readPayload();
        if (payload.roles.indexOf('api_admin') === -1 && payload.roles.indexOf('rsp') === -1 && enseignant.delegataire.login !== payload.login) {
            window.alert("Vous n'êtes pas positionné(e) pour la saisie de ce cours. Pour accéder à la saisie du descriptif de ce cours, veuillez contacter le référent ROF du département correspondant.");
        } else {
            window.location.href = "/edition/" + this.props.lib_mention + "/" + this.props.lib_programme + "/" + this.props.lib_semestre.replace(/\//gi, '-') + "/" + this.props.enseignement;
        }
    }

    handleValidation() {
        this.setState({modalValidation: true});
    }

    validateUE() {
        let url = '/rest/private/edition/validate?enseignement=' + this.props.enseignement;
        var myHeaders = new Headers();
        let apptoken = sessionStorage.getItem('apptoken');
        myHeaders.append("jwt-dauphine", apptoken);
        var myOptions = {
            methode: 'GET',
            headers: myHeaders,
            mode: 'cors',
            cache: 'default'
        };

        // Récupération des données
        fetch(url, myOptions).then((response) => response.json()).then((data) => {
            this.setState({modalValidation: false});
            this.refreshEns();
        });
    }


    // ========================================================


    // ========================================================
    searchDelegataire() {
        // creation de header pour les appels connectes
        let payload = SessionToken.readPayload();
        if (payload && payload.isConnected) {
            let apiHttpOptions = SessionToken.getApiHttpOptions();

            let searchDelegataireValue = this.state.selectedDelegataire;

            if (searchDelegataireValue.length >= 3) {
                fetch('/rest/private/edition/search-ui-individu?input=' + searchDelegataireValue, apiHttpOptions)
                    .then((response) => {
                        if (!response.ok) {
                            console.debug("Enseignement['" + this.props.enseignement + "'].search -> error(" + response.status + ") :: '" + response.statusText + "'");
                            throw Error(response.statusText);
                        }
                        return response;
                    })
                    .then((response) => {
                        return response.json();
                    })
                    .then((data) => {
                        if (data && data.length > 0) {
                            if (data.length <= 25) {
                                this.setState({suggestedDelegataires: data})
                            } else {
                                this.setState({suggestedDelegataires: []});
                            }
                        } else {
                            // on doit vérifier si la recherche correspond à une adresse mail, si c'est le cas, on doit proposer "Contact Externe"
                            if (/^[a-z0-9A-Z]+[a-z0-9A-Z._-]+@[a-z0-9A-Z.-]+\.[a-zA-Z.]{2,5}$/.test(searchDelegataireValue)) {
                                if (searchDelegataireValue.indexOf("dauphine") !== -1) {
                                    this.setState({erreurDauphine: true});
                                } else {
                                    this.setState({suggestedDelegataires: [{id: searchDelegataireValue, contactExterne: 'Contact Externe'}]});
                                }
                            } else {
                                this.setState({suggestedDelegataires: []});
                            }
                        }
                    })
                    .catch((error) => {
                        console.debug(error);
                        this.setState({suggestedDelegataires: []});
                    });

            }
        }
    }

    handleOnSelectDelegataire(delegataire_id) {
        if (delegataire_id !== '00') {
            // creation de header pour les appels connectes
            let payload = SessionToken.readPayload();
            if (payload && payload.isConnected) {
                let apiHttpOptions = SessionToken.getApiHttpOptions();
                fetch('/rest/private/edition/changeDelegataire?enseignement=' + this.props.enseignement + '&individu=' + delegataire_id, apiHttpOptions)
                    .then((response) => {
                        if (!response.ok) {
                            console.debug("API['changeDelegataire'] -> error(" + response.status + ") :: '" + response.statusText + "'");
                            throw Error(response.statusText);
                        }
                        return response;
                    })
                    .then((response) => {
                        // lancement du refresh
                        this.setState({modeEditDelegataire: false, suggestedDelegataires: [], selectedDelegataire: ''});
                        // rafraichir les elements
                        this.refreshEns();
                    })
                    .catch((error) => {
                        console.debug(error);
                        this.setState({suggestedDelegataires: []});
                    });
            }
        }
    }

    handleImpersonate(loginEnseignantResponsable) {
        var apiHttpOptions=SessionToken.getApiHttpOptions();
        if ( apiHttpOptions ) {
            apiHttpOptions.method = 'POST';
            // charge la configuration
            fetch('/rest/private/connected/log-as/' + loginEnseignantResponsable, apiHttpOptions)
                .then(ErrorHandler.process)
                .then((data) => {
                    if (data) {
                        SessionToken.renewToken();
                        SessionToken.readPayload();
                        setTimeout(() => {
                            window.location.href = '/';
                        }, 1000)
                    }
                })
            ;
        }
    }

    // ========================================================

    // ========================================================
    // RENDER PART
    // ========================================================
    renderModalValidation() {
        if (this.state.modalValidation) {
            return (
                <Modal show={true} aria-labelledby="contained-modal-title-vcenter" size='lg' onHide={() => this.handleCloseModal()}>
                    <Modal.Header closeButton>
                        <Modal.Title><span>{this.props.libelle}</span> <span className="smallFont">({this.props.enseignement})</span></Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                        <span>Vous allez confirmer les contenus de cet enseignement. Une fois validés, vous ne pourrez plus les modifier.</span>
                    </Modal.Body>
                    <Modal.Footer>
                        <Button onClick={() => this.validateUE()}>VALIDER</Button>
                    </Modal.Footer>
                </Modal>
            );
        } else {
            return '';
        }
    }

    renderModalSessionEdition() {
        if (this.state.modalSessionEdition) {
            return (
                <Modal show={true} aria-labelledby="contained-modal-title-vcenter" size='lg' onHide={() => this.handleCloseModal()}>
                    <Modal.Header closeButton>
                        <Modal.Title><span>{this.props.libelle}</span> <span className="smallFont">({this.props.enseignement})</span></Modal.Title>
                    </Modal.Header>
                    <Modal.Body className="modal-body-enseignement" closeButton>
                        <BodyEnseignement edit_id={this.props.enseignement} mention={this.props.lib_mention} programme={this.props.lib_programme} semestre={this.props.lib_semestre} overrideShowOk={false} modal={true}/>
                    </Modal.Body>
                </Modal>
            );
        } else {
            return '';
        }
    }

    renderTdLibelle() {
        return (
            <td onClick={() => this.handleViewClick()} className="cursor-pointer">
                <span data-tip data-for={'preview' + this.props.enseignement}>{this.props.libelle + ' (' + this.props.enseignement + ')'}</span>
                <ReactTooltip id={'preview' + this.props.enseignement} type='info'><span>Afficher le descriptif</span></ReactTooltip>
            </td>
        );
    }

    renderTdDelegataireAction() {
        // bouton pour l'edition
        let canChangeDelegataireButton = false;
        if (!this.state.modeEditDelegataire && this.state.ens && this.state.ens.permissions)
            canChangeDelegataireButton = this.state.ens.permissions.changeDelegataire;
        // existe t'il un delegataire (si oui, bouton reset)
        let hasDelegataire = this.hasDelegataire();
        let payload = SessionToken.readPayload();

        // affichage du reset en fonction des roles
        //let payload = SessionToken.readPayload();
        // Rôles :
        // api_users
        // api_admin
        // rsp  (responsable)
        // ass  (assistant)
        // ens  (enseignant)
        // dlg  (delegataire)

        // Ligne originelle
        //if ( payload && payload.isConnected && ( payload.roles.indexOf('rsp')>-1 || payload.roles.indexOf('api_admin')>-1 ) ){

        // 2019-11-18 JCG : N'est plus utile
        //if ( payload && payload.isConnected && ( payload.roles.indexOf('rsp')>-1 ) ){
        //
        //      ==== DELEGATAIRE (avec retour arriere)
        //               SI ADMIN ou RESPONSABLE
        //
        return (
            <td>
                {(payload.roles.indexOf('rsp') > -1 || payload.roles.indexOf('api_admin') > -1 || payload.roles.indexOf('ass') > -1) ?
                    hasDelegataire ?
                        <div>
                            <Button data-tip data-for={'delegataire_del_' + this.props.enseignement} variant="outline-secondary" onClick={() => this.handleResetDelegataireButton()} disabled={!canChangeDelegataireButton}>
                                <FontAwesomeIcon icon={faUndo}/>
                            </Button>
                            <ReactTooltip id={'delegataire_del_' + this.props.enseignement} type='info'><span>Revenir à l'enseignant-responsable défini dans ROF : {this.state.enseignantNom}</span></ReactTooltip>
                        </div>
                        :
                        <div>
                            <Button data-tip data-for={'delegataire_set_' + this.props.enseignement} variant="outline-secondary" onClick={() => this.handleEditDelegataireButton()} disabled={!canChangeDelegataireButton}>
                                <FontAwesomeIcon icon={faPencilAlt}/>
                            </Button>
                            <ReactTooltip id={'delegataire_set_' + this.props.enseignement} type='info'><span>Désigner un enseignant-délégataire</span></ReactTooltip>
                        </div>
                    : ""
                }
            </td>
        );
        /* // 2019-11-18 JCG : N'est plus utile
        } else {
            // 
            //      ==== DELEGATAIRE (sans retour arriere)
            //
            return (
                <td>
                    {hasDelegataire ?
                        <div>
                            <Button data-tip data-for={'delegataire_del_deleg_'+this.props.enseignement} variant="outline-secondary" disabled={true}>
                                <FontAwesomeIcon icon={faUser} />
                            </Button>
                            <ReactTooltip id={'delegataire_del_deleg_'+this.props.enseignement} type='info'><span>Enseignant délégataire déjà saisi</span></ReactTooltip>
                        </div>
                        :
                        <div>
                            <Button data-tip data-for={'delegataire_set_'+this.props.enseignement} variant="outline-secondary" onClick={() => this.handleEditDelegataireButton()} disabled={!canChangeDelegataireButton}>
                                <FontAwesomeIcon icon={faUser} />
                            </Button>
                            <ReactTooltip id={'delegataire_set_'+this.props.enseignement} type='info'><span>Définir un enseignant-délégataire</span></ReactTooltip>
                        </div>
                    }
                </td>
            );
        }
        */
    }

    renderTdDelegataireValue() {
        let payload = SessionToken.readPayload();
        let isAdminRSP = false;
        if (payload && payload.isConnected) {
            if (payload.roles.indexOf('api_admin') > 0 || payload.roles.indexOf('rsp') > 0) {
                isAdminRSP = true;
            }
        }
        let name = "Pas d'enseignant";
        let email = "Pas d'adresse email";
        let tooltip = "Aucun enseignant responsable/délégataire renseigné";
        let enseignantResponsable = this.getEnseignant();
        let loginEnseignantResponsable = null;
        //let delegataire = this.getDefaultDelegataire();
        if (enseignantResponsable && enseignantResponsable.delegataire) {
            tooltip = enseignantResponsable.tooltip;
            name = enseignantResponsable.delegataire.nom + " " + enseignantResponsable.delegataire.prenom;
            if (enseignantResponsable.delegataire.email)
                email = (<a href={'mailto:' + enseignantResponsable.delegataire.email}>{enseignantResponsable.delegataire.email}</a>);
            loginEnseignantResponsable = enseignantResponsable.delegataire.login;
        }

        let isModeEdit = this.state && this.state.modeEditDelegataire;

        let resultHtml = null;
        if (!isModeEdit) {
            resultHtml = (
                <td>
                    <span data-tip data-for={'delegataire-type' + this.props.enseignement}>{name}</span>
                    <ReactTooltip id={'delegataire-type' + this.props.enseignement} type='info'><span>{tooltip}</span></ReactTooltip>
                    {
                        isAdminRSP && loginEnseignantResponsable ?
                            <div className="d-inline">
                                <Button className="spacingLeft btn btn-warning" size="sm" variant="outline-secondary"
                                        data-tip data-for={'log-as-' + this.props.enseignement}
                                        onClick={() => this.handleImpersonate(loginEnseignantResponsable)}>
                                    <FontAwesomeIcon icon={faSignInAlt}/>
                                </Button>
                                <ReactTooltip id={'log-as-' + this.props.enseignement} type='info'><span>Naviguer</span></ReactTooltip>
                            </div>
                        : ""
                    }
                    <br/>{email}
                </td>
            );
        } else {

            // ============================================================
            //  ORIGINAL mais LES SUGGESTIONS NE S'AFFICHENT PLUS
            // ============================================================
            /*
            resultHtml = (
                <td>
                    
                    <Autocomplete
                        inputProps={{ id: 'delegataire-autocomplete' }}
                        value={this.state.selectedDelegataire}
                        items={this.state.suggestedDelegataires}
                        getItemValue={item => item.id}
                        onChange={event => this.handleOnChangeDelegataire(event)}
                        onSelect={value => this.handleOnSelectDelegataire(value)}
                        renderMenu={children => (
                            <Dropdown.Menu show={true}>
                                {this.state.suggestedDelegataires.length === 0 ?
                                    (<Dropdown.Item key={'00'} eventKey="00">Affiner votre recherche</Dropdown.Item>)
                                    : children
                                }
                            </Dropdown.Menu>
                        )}
                        renderItem={(item, isHighlighted) => (
                            <Dropdown.Item key={item.id}><small>{item.nom + ', ' + item.prenom}</small></Dropdown.Item>
                        )}
                        // wrapperStyle={{ width: '100%' }}
                    />
                </td>
            );
            */


            // ============================================================
            //  PATCH 'UGLY' mais sinon ca ne marche pas
            // ============================================================
            resultHtml = (
                <td>
                    <Autocomplete
                        inputProps={{id: 'delegataire-autocomplete'}}
                        value={this.state.selectedDelegataire}
                        items={this.state.suggestedDelegataires}
                        getItemValue={item => item.id}
                        onChange={event => this.handleOnChangeDelegataire(event)}
                        onSelect={value => this.handleOnSelectDelegataire(value)}
                        renderMenu={children => (
                            <div className="menu">
                                {this.state.suggestedDelegataires.length === 0 ?
                                    (this.state.erreurDauphine) ?
                                        (<div className='item' key='00' style={{background: 'lightgray'}}><i>Il n'est pas possible de renseigner manuellement une adresse mail dauphinoise</i></div>)
                                        :
                                        (<div className='item' key='00' style={{background: 'lightgray'}}><i>Affiner votre recherche</i></div>)
                                    : children
                                }
                            </div>
                        )}
                        renderItem={(item, isHighlighted) => (
                            <div
                                className={`item ${isHighlighted ? 'item-highlighted' : ''}`}
                                key={item.id}
                            >
                                <small>{item.contactExterne ? item.contactExterne : item.nom + ', ' + item.prenom}</small>
                            </div>
                        )}
                        // wrapperStyle={{ width: '100%' }}
                    />
                </td>
            );

        }
        return resultHtml;
    }

    renderStatusIcone(etat) {
        let result = (<FontAwesomeIcon icon={faFile}/>);
        if (etat) {
            switch (etat) {
                case 0:
                    result = (<FontAwesomeIcon icon={faFile}/>);
                    break;
                case 1:
                case 2:
                    result = (<FontAwesomeIcon icon={faPaperPlane}/>);
                    break;
                case 3:
                    result = (<FontAwesomeIcon icon={faPencilAlt}/>);
                    break;
                case 4:
                    result = (<FontAwesomeIcon icon={faCheck}/>);
                    break;
                case 5:
                    result = (<FontAwesomeIcon icon={faUpload}/>);
                    break;
                default:
                    result = (<FontAwesomeIcon icon={faQuestion}/>);
                    break;
            }
        }
        return result;
    }

    renderStatusLibelle(etat) {
        let result = "A faire";
        if (etat) {
            switch (etat) {
                case 0:
                    result = "A faire";
                    break;
                case 1:
                    result = "Invité";
                    break;
                case 2:
                    result = "Réinvité";
                    break;
                case 3:
                    result = "Modifié";
                    break;
                case 4:
                    result = "Validé";
                    break;
                case 5:
                    result = "Confirmé";
                    break;
                default:
                    result = "Inconnu";
                    break;
            }
        }
        return result;
    }

    renderTdStatus() {
        let etat = 0;
        let dateModif = "non modifié";
        let date_options = {year: 'numeric', day: '2-digit', month: '2-digit', hour: '2-digit', minute: '2-digit'};
        if (this.state && this.state.ens) {
            if (this.state.ens.etat)
                etat = this.state.ens.etat;
            if (this.state.ens.dateModificationFr)
                dateModif = new Date(this.state.ens.dateModificationFr).toLocaleDateString('fr-FR', date_options);
        }
        let etatLibelle = this.renderStatusLibelle(etat);
        let etatIcone = this.renderStatusIcone(etat);

        return (
            <td>
                {etatIcone} {etatLibelle}
                <br/>
                {etat > 0 ? dateModif : ""}
            </td>
        );
    }

    renderTdActions() {
        // bouton invite ou reinvite
        let inviteEnable = false;
        let canInvite = false;
        let exportEnable = false;
        let editionEnable = false;
        let payload = SessionToken.readPayload();
        if (this.state && this.state.ens && this.state.ens.permissions) {
            // attention est-ce que le delegataire a un mail
            let delegataire = this.getDefaultDelegataire();
            canInvite = this.state.ens.permissions.invitation;
            if (delegataire !== null && delegataire.email)
                inviteEnable = this.state.ens.permissions.invitation && !this.state.waitButtonInvite;
            // autres boutons
            exportEnable = this.state.ens.permissions.confirmAndExport;
            editionEnable = this.state.ens.permissions.edit || this.state.ens.permissions.editAfterValidate;
        }
        let inviteLabel = "Inviter";
        if (this.state && this.state.ens && this.state.ens.etat && this.state.ens.etat >= 1)
            inviteLabel = "Réinviter";
        if (this.state.waitButtonInvite)
            inviteLabel = "En Cours";

        let inviteIcon = faPaperPlane;
        if (this.state.waitButtonInvite)
            inviteIcon = faHourglass;

        let exportStatus = null;
        if (this.state.exportSuccessStatus)
            exportStatus = this.state.exportSuccessStatus;

        // FOR_TEST
        //exportStatus='failed';

        let exportMessage = "export en erreur";
        if (exportStatus && exportStatus === 'success')
            exportMessage = "cours exporté";

        return (
            <td>
                {(this.state.ens.etat < 4) ?
                    <Button className="spacingLeft" size="sm" variant="outline-secondary" onClick={() => this.handleValidation()} disabled={!editionEnable || this.state.ens.remplissage === 0}>
                        <FontAwesomeIcon icon={faCheck}/>
                    </Button>
                    :
                    ""
                }

                {canInvite ?
                    <Button className="spacingLeft" size="sm" variant='outline-secondary' onClick={() => this.handleInviteButton()} disabled={!inviteEnable}>
                        <FontAwesomeIcon icon={inviteIcon}/> {inviteLabel}
                    </Button>
                    :
                    ""
                }

                {(payload.roles.indexOf('rsp') > -1 || payload.roles.indexOf('api_admin') > -1) ?
                    exportStatus ?
                        <span class={exportStatus === 'success' ? 'alert alert-success' : 'alert alert-danger'}>{exportMessage}</span>
                        :
                        <Button className="spacingLeft" size="sm" variant='outline-secondary' onClick={() => this.handleExportButton()} disabled={!exportEnable}>
                            <FontAwesomeIcon icon={faUpload}/> Exporter dans ROF
                        </Button>
                    : ""
                }
                <Button className="spacingLeft" size="sm" variant='outline-secondary' onClick={() => this.handleEditButton()} disabled={!editionEnable}>
                    <FontAwesomeIcon icon={faEdit}/>
                </Button>
                {this.state.ens.remplissage === 0 ?
                    <Button className="spacingLeft" size="sm" variant='danger' disabled={true}>
                    </Button>
                    :
                    this.state.ens.remplissage === 1 ?
                        <Button className="spacingLeft" size="sm" variant='warning' disabled={true}>
                        </Button>
                        :
                        <Button className="spacingLeft" size="sm" variant='success' disabled={true}>
                        </Button>
                }

            </td>
        );
    }

    // ========================================================


    render() {
        let tdLibelle = this.renderTdLibelle();
        let modalPreview = this.renderModalSessionEdition();

        let tdDelegataireAction = this.renderTdDelegataireAction();
        let tdDelegataireValue = this.renderTdDelegataireValue();

        let tdStatus = this.renderTdStatus();
        let tdActions = this.renderTdActions();

        let modalValidation = this.renderModalValidation();

        return (
            <tr>
                {modalPreview}
                {modalValidation}
                {tdLibelle}
                {tdDelegataireAction}
                {tdDelegataireValue}
                {tdStatus}
                {tdActions}
            </tr>
        );
    }
}

// =================================
// Propriété attendu du composant
// =================================
Enseignement.propTypes = {
    annee: PropTypes.string,
    mention: PropTypes.string,
    programme: PropTypes.string,
    composante: PropTypes.string,
    semestre: PropTypes.string,
    enseignement: PropTypes.string,
    libelle: PropTypes.string
};
