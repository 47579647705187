import React, { Component } from 'react'
import Mention from './Mention';
import { ListGroup } from 'react-bootstrap';
import SessionToken from './../SessionToken';

export default class BodyBackend extends Component {

    constructor(props) {
        super(props);

        this.state = {
            annee: null,
            preview: null,
            mentions: [],
            nbEnseignements: null,
            selectedMention: null,
            selectedMentionsCollapse: [],
            enseignants: []
        }

        // handlers
        this.handleExpandMention    =this.handleExpandMention.bind(this);
        this.handleCollapseMention  =this.handleCollapseMention.bind(this);
    }


    componentDidMount() {
        console.debug("mounted: BodyBackend");

        // Récupération de l'année
        fetch('/rest/public/edition/annee')
            .then((response) => {
                return response.text();
            })
            .then((data) => {
                this.setState({ annee: data });
            })

        // Récupération de la liste des enseignants
        let payload =SessionToken.readPayload();
        let mentionServiceString = "";
        if ( payload && payload.isConnected ){
            if ((payload.roles.indexOf('dlg') > 0 || payload.roles.indexOf('ens') > 0)
                && payload.roles.indexOf('api_admin') === -1
                && payload.roles.indexOf('rsp') === -1) {
                mentionServiceString = '/rest/private/edition/mentions-enseignant';
            }
            else {
                mentionServiceString = '/rest/private/edition/mentions';
            }
            let apiHttpOptions  =SessionToken.getApiHttpOptions();
            fetch('/rest/private/edition/individus', apiHttpOptions)
                .then((response) => {
                    return response.json();
                })
                .then((data) => {
                    this.setState({ enseignants: data });
                });
            // Affichage dynamique des champs
            fetch('/rest/private/edition/preview', apiHttpOptions)
                .then((response) => {
                    return response.json();
                })
                .then((data) => {
                    this.setState({ preview: data });
                });
            // Récupération des données
            fetch(mentionServiceString, apiHttpOptions)
                .then((response) => {
                    return response.json();
                })
                .then((data) => {
                    this.setState({ mentions: data });
                });
            if ((payload.roles.indexOf('dlg') > 0 || payload.roles.indexOf('ens') > 0)
                && payload.roles.indexOf('api_admin') === -1
                && payload.roles.indexOf('rsp') === -1) {
                fetch('/rest/private/edition/nb-enseignements-enseignant', apiHttpOptions)
                    .then((response) => {
                        return response.json();
                    })
                    .then((data) => {
                        this.setState({ nbEnseignements: data});
                    });
            }
        }
    }

    // HANDLERS =================================================================================
    handleExpandMention(cod_men){
        var array = [...this.state.selectedMentionsCollapse];
        array.splice(array.indexOf(cod_men), 1);
        this.setState({selectedMention: cod_men, selectedMentionsCollapse: array});
    }
    handleCollapseMention(cod_men){
        this.setState({selectedMention: null, selectedMentionsCollapse: [...this.state.selectedMentionsCollapse, cod_men]});
    }

    // RENDERS ==================================================================================
    render() {

        let selected=this.state.selectedMention;
        let selectedCollapse=this.state.selectedMentionsCollapse;
        const listMention = this.state.mentions.map( (item) => <Mention
                        key={"backend_mention_"+item.cod_men}
                        annee={this.state.annee} mention={item.cod_men} libelle={item.lib_men} nbEnseignements={this.state.nbEnseignements}
                        expand={ (item.cod_men===selected && selectedCollapse.indexOf(item.cod_men) === -1) || (this.state.nbEnseignements != null && this.state.nbEnseignements <= 20 && (selectedCollapse.indexOf(item.cod_men) === -1)) }
                        handleExpandMention={this.handleExpandMention} handleCollapseMention={this.handleCollapseMention}
                    />);

        return (
                <div id="backend-mentions">
                    <ListGroup key="main_list_group">
                        { listMention }
                    </ListGroup>
                </div>
            );
    }


}
