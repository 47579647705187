import React from 'react';
import ReactDOM from 'react-dom';
//import './index.css';
import Layout from './components/Layout'
import * as serviceWorker from './serviceWorker';

// Let's try some console logging...
const debug_mode = true;

if( !debug_mode ) {
    console.debug = () => {};
}

ReactDOM.render(<Layout />, document.getElementById('root'));

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
