export default class ErrorHandler {

    static process(response) {
        if (response.status === 204) return null;
        let json = response.json();
        if (!response.ok) {
            return json.then(Promise.reject.bind(Promise));
        } else {
            return json;
        }
    }
}