export default class SessionToken {

    // ~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~
    // Renouvelle le token
    // ~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~
    static renewToken(){
        let apptoken = sessionStorage.getItem('apptoken');
        if ( apptoken ){
            // creation de header pour les appels connectes
            var myHeaders = new Headers();
            if (apptoken)
                myHeaders.append("jwt-dauphine", apptoken);
            var myOptions = {methode: 'GET',headers: myHeaders,mode: 'cors',cache: 'default'};
            // API['renew-token'] => call ...
            fetch('/rest/private/connected/renew-token', myOptions)
                .then((response) => {
                    let newtoken   =response.text();
                    // retour
                    return newtoken;
                })
                .then((data) => {
                    if ( data && data.length>0 ){
                        console.debug("RENEW-TOKEN -> success (size="+data.length+")");

                        // chargement des roles
                        SessionToken.renewRoles(data);
                        // enregistrement du token
                        sessionStorage.setItem('apptoken',data);
                        
                    } else {
                        console.debug("RENEW-TOKEN -> failed");
                    }
                })
        }
    }

    static renewRoles(apptoken){
        if ( apptoken ){
            console.debug("extends APPTOKEN via API ...");
            // creation de header pour les appels connectes
            var myHeaders = new Headers();
            if (apptoken)
                myHeaders.append("jwt-dauphine", apptoken);
            var myOptions = {methode: 'GET',headers: myHeaders,mode: 'cors',cache: 'default'};
            // API['renew-token'] => call ...

            let request = new XMLHttpRequest();
            request.open('GET', '/rest/private/roles', false);
            if (apptoken)
                request.setRequestHeader('jwt-dauphine', apptoken);
            request.send();
            if (request.responseText) {
                console.debug("RENEW-ROLES -> success (size="+request.responseText.length+") : "+request.responseText);
                sessionStorage.setItem('roles',request.responseText);
                console.debug("extends APPTOKEN via API -> "+request.responseText);
            }

            // fetch('/rest/private/roles', myOptions)
            //     .then((response) => {
            //         return response.text();
            //     })
            //     .then((data) => {
            //         if ( data && data.length>0 ){
            //             console.debug("RENEW-ROLES -> success (size="+data.length+") : "+data);
            //             sessionStorage.setItem('roles',data);
            //             console.debug("extends APPTOKEN via API -> "+data);
            //         } else {
            //             console.debug("RENEW-ROLES -> failed");
            //         }
            //     })
        } else {
            console.debug("extends APPTOKEN via API -> [ignore]");
        }
    }

    static b64DecodeUnicode(str) {
        // Going backwards: from bytestream, to percent-encoding, to original string.
        return decodeURIComponent(atob(str).split('').map(function(c) {
            return '%' + ('00' + c.charCodeAt(0).toString(16)).slice(-2);
        }).join(''));
    }

    // ~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~
    // récupération des informations du token (à lancer avant chaque render)
    //    SI NON CONNECTED   => {login: null, displayName: 'Non connecté', roles: null,  isConnected: false}
    //    SI CONNECTED       => {login: ...,  displayName: ...,            roles: [...], isConnected: true }
    // ~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~
    static readPayload(){
        let result      ={login: null, displayName: 'Non connecté', roles: null, isConnected: false};
        let apptoken    =sessionStorage.getItem('apptoken');
        if ( apptoken && apptoken.split('.').length === 3 ){
            let payload64=apptoken.split('.')[1];
            let payloadStr=SessionToken.b64DecodeUnicode( payload64 );
            let payload =JSON.parse( payloadStr );
            let epochNow    =Math.floor(new Date().getTime()/1000);
            let epochToken  =payload.exp;
            if ( epochNow<epochToken ){
                result.isConnected=true;
                result.login=payload.userid;
                result.displayName=payload.userid;
                if ( payload.attributes ){
                    payload.attributes.split('%').forEach( (item) => {
                        if ( item && item.startsWith('displayname=') ){
                            result.displayName=item.replace('displayname=','');
                        }
                        if ( item && item.startsWith('back=') ){
                            result.back=item.replace('back=','');
                        }
                    });
                }

                // ROLES par TOKEN
                result.roles=[];
                if ( result.roles.length===0 && (payload.roles && payload.roles.split('%').length>0) ){
                    // ROLES >>> INNER PAYLOAD
                    result.roles=payload.roles.split('%');
                    sessionStorage.setItem('roles',payload.roles);
                }
                // ROLES par API
                if ( result.roles.length===0 && !sessionStorage.getItem('roles') ){
                    // ROLES >>> renouvellement par API
                    SessionToken.renewRoles(apptoken);
                }
                if ( result.roles.length===0 && (sessionStorage.getItem('roles') && sessionStorage.getItem('roles').split('%').length>0) ){
                    // ROLES >>> via le sessionStorage associé
                    result.roles=sessionStorage.getItem('roles').split('%');
                }

                // renouvellement du token (5 min avant expiration)
                if ( epochNow+300>epochToken )
                    // session bientot expire (renew)
                    SessionToken.renewToken();

            } else {
                console.debug("Session(Token) -> expiré [exp="+epochToken+" < now="+epochNow+"]");
                sessionStorage.removeItem('apptoken');
            }
        } else {
            console.debug("Session(Token) -> no token");
        }
        return result;
    }


    // Donne les parametres de session a l'API
    static getApiHttpOptions() {
        var requestOptions = null;
        // --------------------------------------------
        // vérification du token
        // --------------------------------------------
        let payload =SessionToken.readPayload();
        // --------------------------------------------
        // construction du header
        // --------------------------------------------
        if ( payload && payload.isConnected ) {
            let apptoken = sessionStorage.getItem('apptoken');
            if ( apptoken && apptoken.split('.').length === 3 ){
                // --------------------------------------------
                // creation de header pour les appels connectes
                // --------------------------------------------
                var myHeaders = new Headers();
                if (apptoken) myHeaders.append("jwt-dauphine", apptoken);
                requestOptions = {method: 'GET', headers: myHeaders, mode: 'cors', cache: 'default'};
            }
        }
        return requestOptions;
    }

    static checkRights(rights) {
        let payload =SessionToken.readPayload();
        if ( !payload || !payload.isConnected ) {
            window.location.assign('/auth/client');
        } else if ( !payload.roles || payload.roles.indexOf('api_users')===-1 ){
            window.location.assign('/error/403A');
        } else if (rights.length>0) {
            let hasRight = false;
            rights.forEach(right => {if (payload.roles.indexOf(right)>=0) hasRight=true;});
            if (!hasRight) {
                window.location.assign('/error/403B');
            }
        }

    }

    static isRespMention(mention) {
        let payload =SessionToken.readPayload();
        if (payload.roles.indexOf("api_admin")>=0) {
            return true;
        } else {
            return payload.roles.indexOf("rsp_men_"+mention)>=0;
        }
    }

}
