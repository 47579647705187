import React, {Component} from 'react'

import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';

import jwt from 'jsonwebtoken';

import Headers from './Headers';
import Footer from './Footer';
import BodyAdmin from './admin/BodyAdmin';
import BodyConfig from './admin/BodyConfig';
import BodyTemplateMail from './admin/BodyTemplateMail';
import BodyDateFinCampagne from './admin/BodyDateFinCampagne';
import BodyExtractions from './admin/BodyExtractions';
import BodyAide from './admin/BodyAide';
import BodyBackend from './backend/BodyBackend';
import BodyEnseignement from './edition/BodyEnseignement';
import BodyValidation from './validation/BodyValidation';
import BodyHelp from './help/BodyHelp';
import BodyError from './BodyError';
import SessionToken from './SessionToken';

import '../App.css';

/*
Roles :
* api_admin => Get page admin
* api_users => Get ??
* api_viewer => Viewer (page des parcours)
* rsp   => responsable (page des parcours)
* ass   => assistant (page des parcours)
* ens   => enseignant (page de l'enseignement)
* dlg   => delegataire (page de l'enseignement)
* trd   => traducteur ???
* rsp_men_<<code mention>>
* ass_men_<<code_mention>>
*/

export default class Layout extends Component {

    constructor(props) {
        super(props);
        this.state = {
            components: "",
            appName: "MySyllabus",
            page: "back",
            edit_id: 'EN0IBUSX01',
        }
    }

    // -------------------------------------------------------
    // Chargement du token si present dans la requete GET
    //  1- recuperation from url                 => URL?<<get-params['apptoken']>> => enregistrement dans sessionStorage
    //  2) re-ecriture de l'url sans le token    => URL?<<other-get-params>>
    // -------------------------------------------------------
    manageTokenFromUrl(url) {
        if (!sessionStorage.getItem('url') && (url.indexOf('/edition') >= 0 || url.indexOf('/validation') >= 0)) {
            sessionStorage.setItem("url", url);
        }
        let ind = url.indexOf('apptoken');
        let token = '';
        if (ind >= 0) {
            // We have a token
            token = url.slice(9 + ind);
            let tmp = jwt.decode(token);
            // Gestion du storage
            if (!tmp || !tmp.sub) {
                console.debug("Bad token");
            } else {
                // chargement des roles (attention : asynchrone bloquant)
                SessionToken.renewRoles(token);
                // ajout du login
                sessionStorage.setItem("login", tmp.sub);
                // ajout du token
                sessionStorage.setItem("apptoken", token);
            }

            // ~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~
            // lancer une redirection uniquement après 0.9 sec
            //      => normalement renewRoles() sera termine
            setTimeout(function () {
                // Renvoie de l'URL sans le token
                let ind1 = url.indexOf('?');
                let query = url.slice(ind1 + 1);
                let uri = url.slice(0, ind1);
                let tok = query.split('&');
                let nquery = "";
                let beg = true;
                tok.forEach((value) => {
                    if (!value.startsWith('apptoken=')) {
                        if (!beg) {
                            nquery += '&';
                        } else {
                            nquery += '?';
                            beg = false;
                        }
                        nquery += value;

                    }
                });
                if (sessionStorage.getItem('url') && sessionStorage.getItem('url').indexOf('apptoken') < 0) {
                    uri = sessionStorage.getItem('url');
                    sessionStorage.removeItem('url');
                    window.location.assign(uri);
                } else {
                    window.history.pushState("", "", uri + nquery);
                }
            }, 2000);
            // ~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~

        } else {
            if (!sessionStorage.getItem('apptoken'))
                console.debug("WebApp -> no apptoken {'url' or 'sessionStore'}");
        }
    }

    componentWillMount() {
        console.debug("layout will mount");

        // --------------------------------------------------
        // chargement du token si present dans l'URL
        // --------------------------------------------------

        let uri = window.location.pathname + window.location.search;
        this.manageTokenFromUrl(uri);

        // --------------------------------------------------
        // dispatcher URL
        // --------------------------------------------------
        let page = '/';
        let edit_id = null;
        let mention = "";
        let programme = "";
        let semestre = "";
        // Gestion des accès directs...
        if (uri.startsWith("/edition/") || uri.startsWith("/validation/")) {
            let tabSep = uri.split('/');
            console.log("tabSep", tabSep);
            // 0 is "", 1 is "edition", 2 is mention
            if (tabSep.length >= 5) {
                mention = tabSep[2];
                programme = tabSep[3];
                semestre = tabSep[4];
            }
            let sschain = tabSep[tabSep.length - 1];
            console.debug("sschain", sschain);
            let tabGet = sschain.split('?');
            console.debug("tabGet", tabGet);
            let id_ue = tabGet[0];
            if (uri.startsWith("/edition/")) {
                page = "/edit";
            } else {
                page = "/valid";
            }
            edit_id = id_ue;
        } else {
            if (uri.startsWith("/aide")) {
                page = uri.split("?")[0];
            } else {
                page = uri
            }
        }

        // ECRITURE DU STATE
        console.debug("Session(state) -> {page:'" + page + "',edit_id: '" + edit_id + "'}");
        this.setState({
            page: page,
            edit_id: edit_id,
            edit_mention: mention,
            edit_programme: programme,
            edit_semestre: semestre,
        });
        // ===================================================

    }

    render() {
        let payload = SessionToken.readPayload();
        let body = "";

        let overrideShowOk = false;
        if (payload.roles && (payload.roles.indexOf('api_admin') !== -1 || payload.roles.indexOf('rsp') !== -1)) {
            overrideShowOk = true;
        }

        switch (this.state.page) {
            case "/error/401":
                body = (<BodyError type='401'/>);
                break;
            case "/error/403A":
                body = (<BodyError type='403' level="application"/>);
                break;
            case "/error/403B":
                body = (<BodyError type='403' level="page"/>);
                break;
            case "/admin/codeens":
                SessionToken.checkRights(['api_admin', 'rsp']);
                body = (<BodyAdmin/>);
                break;
            case "/admin/config":
                SessionToken.checkRights(['api_admin', 'rsp', 'ass']);
                body = (<BodyConfig/>);
                break;
            case "/admin/templatemail":
                SessionToken.checkRights(['api_admin']);
                body = (<BodyTemplateMail/>);
                break;
            case "/admin/datefincampagne":
                SessionToken.checkRights(['api_admin', 'rsp']);
                body = (<BodyDateFinCampagne/>);
                break;
            case "/admin/extractions":
                SessionToken.checkRights(['api_admin', 'rsp']);
                body = (<BodyExtractions/>);
                break;
            case "/admin/aides":
                SessionToken.checkRights(['api_admin']);
                body = (<BodyAide/>);
                break;
            case "/edit":
                SessionToken.checkRights([]);
                body = (<BodyEnseignement edit_id={this.state.edit_id} mention={this.state.edit_mention} programme={this.state.edit_programme} semestre={this.state.edit_semestre} overrideShowOk={overrideShowOk}
                                          modal={false}/>);
                break;
            case "/valid":
                SessionToken.checkRights([]);
                body = (<BodyValidation edit_id={this.state.edit_id}/>);
                break;
            case "/aide":
                SessionToken.checkRights([]);
                body = (<BodyHelp/>);
                break;
            case "/":
            case "/backend":
            default:
                SessionToken.checkRights(['api_admin', 'rsp', 'ass', 'ens', 'dlg']);
                body = (<BodyBackend/>);
                break;
        }

        return (
            <Container className="layout" fluid>
                <Row>
                    <Headers title={this.state.appName}/>
                </Row>
                <Row className="card card-dauphine card-principale">
                    <div className="card-body" style={{'marginBottom': '56px'}}>
                        {body}
                    </div>
                </Row>
                <Row>
                    <Footer/>
                </Row>
            </Container>
        );
    }
}
