import React from 'react';
import { Alert, Button } from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {faExpeditedssl} from '@fortawesome/free-brands-svg-icons';

export default class BodyError extends React.Component {
    constructor(props) {
        super(props);
        this.handleSeConnecter   =this.handleSeConnecter.bind(this);
    }

    handleSeConnecter(){
        window.location.assign('/auth/client');
    }

    render() {
        

        return (
            <div>
                <div className="title"><span>
                    { this.props.type==='401' ? 'Authentification requise' : '' }
                    { this.props.type==='403' ? 'Droits insuffisants': '' }
                    { this.props.type==='404' ? 'Page inexistante' : '' }
                </span></div>

                    { this.props.type==='401' ?
                        <div><p>Veuillez-vous connecter en cliquant sur le bouton ci-dessous :</p>
                             <Button variant='primary' onClick={this.handleSeConnecter}><FontAwesomeIcon icon={faExpeditedssl} size="lg" /> Se connecter</Button>
                        </div> : '' }
                    { this.props.type==='403' ? <p>Votre accès n'a pas pu être validé. Pour réessayer <a href="/"> cliquez ici</a></p> : '' }
                    { this.props.type==='404' ? <Alert variant='danger'><p>La page {this.props.page} n'existe pas</p></Alert> : '' }

                </div>
            )
    }
}