import React, { Component } from 'react';
import { Table } from 'react-bootstrap';
import SessionToken from './../SessionToken';

export default class BodyExtractions extends Component {

    constructor(props) {
        super(props);
        this.state = {
            extractions: []
        }
    }

    componentWillMount() {
        // creation de header pour les appels connectes
        var apiHttpOptions=SessionToken.getApiHttpOptions();
        if (apiHttpOptions) {
            fetch('/rest/private/edition/extractions', apiHttpOptions)
                .then((response) => {
                    return response.json();
                })
                .then((data) => {
                    this.setState({ extractions: data});
                });
        }
    }

    render() {
        let date_options = { year: 'numeric', day: '2-digit', month: '2-digit', hour: '2-digit', minute: '2-digit' };
        let listExtractions;
        if (this.state.extractions && this.state.extractions.length > 0) {
            listExtractions = this.state.extractions.map((item, index) => {
                let dateExportFr = "";
                let dateExportEn = "";
                let datePubFr = "";
                let datePubEn = "";
                if (item.dateExportFr) {
                    dateExportFr = new Date(item.dateExportFr).toLocaleDateString('fr-FR', date_options);
                }
                if (item.dateExportEn) {
                    dateExportEn = new Date(item.dateExportEn).toLocaleDateString('fr-FR', date_options);
                }
                if (item.datePublicationFr) {
                    datePubFr = new Date(item.datePublicationFr).toLocaleDateString('fr-FR', date_options);
                }
                if (item.datePublicationEn) {
                    datePubEn = new Date(item.datePublicationEn).toLocaleDateString('fr-FR', date_options);
                }
                if(dateExportFr!==datePubFr) {
                    datePubFr = <b className="text-orange">{datePubFr}</b>;
                }
                if(dateExportEn!==datePubEn) {
                    datePubEn = <b className="text-orange">{datePubEn}</b>;
                }

                return (
                    <tr key={index}>
                        <td>{item.cod_men}<br/></td>
                        <td>{item.lib_men}</td>
                        <td>{dateExportFr}</td>
                        <td>{dateExportEn}</td>
                        <td>{datePubFr}</td>
                        <td>{datePubEn}</td>
                    </tr>
                );
            });
        }


        return (
            <div>
                <div className="title"><span>Administration - Tableau des extractions</span></div>
                <Table hover className="table_enseignements table_dauphine table_centered table-bordered table_small table_extractions">
                    <thead>
                        <tr>
                            <th>Code Mention</th>
                            <th>Libellé Mention</th>
                            <th>Date d'extraction (Fr)</th>
                            <th>Date d'extraction (En)</th>
                            <th>Date API (Fr)</th>
                            <th>Date API (En)</th>
                        </tr>
                    </thead>
                    <tbody>
                        { listExtractions }
                    </tbody>
                </Table>
            </div>
        )
    }
}