import React, { Component } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faSpinner } from '@fortawesome/free-solid-svg-icons';
import { Alert, Button, Col, Form, Row } from 'react-bootstrap';
import { debounce } from 'lodash';
import Autocomplete from  'react-autocomplete';
import SessionToken from './../SessionToken';


export default class BodyAdmin extends Component {

    constructor(props) {
        super(props);

        this.state = {
            selectedAnnee: "",
            optionsAnnee: [],
            selectedEnseignement: "",
            selectedEnseignementId: null,
            suggestedEnseignements: [],
            newCodeEnseignement: null,
            isLoading: false,
            alertMsg: null,
            alertType: 'danger'
        };
        // handle form element
        this.handleChangeAnnee = this.handleChangeAnnee.bind(this);
        this.handleSelectEnseignement = this.handleSelectEnseignement.bind(this);
        this.handleNewCodeEnseignement = this.handleNewCodeEnseignement.bind(this);
        this.handleChangeEnseignement = (event) => {
            this.setState({selectedEnseignement:event.target.value, alertMsg:null});
            if (!this.debouncedFn) {
                 this.debouncedFn = debounce(()=>{
                     this.searchEnseignement()
                 }, 500);
            }
            this.debouncedFn();
        };
        // handle submit
        this.handleSubmit = this.handleSubmit.bind(this);

    }

    componentWillMount() {
        // creation de header pour les appels connectes
        var apiHttpOptions=SessionToken.getApiHttpOptions();
        if ( apiHttpOptions ){
            // charge la liste des annees disponible
            fetch('/rest/private/edition/annees', apiHttpOptions)
                .then((response) => {
                    return response.json();
                })
                .then((data) => {
                    console.log("DATA ['" + data.length + "'] RAW " + JSON.stringify(data));
                    if (data && data.length > 0) {
                        console.log("SELECT ANNEE => '" + data[0] + "'");
                        let options = [];
                        data.forEach(annee => options.push({ value: annee, label: annee }));
                        this.setState({
                            //selectedAnnee: data[0],
                            optionsAnnee: options
                        })
                    }
                })

        }
    }


    handleChangeAnnee(event) {
        this.setState({ selectedAnnee: event.target.value, alertMsg: null });
    }

    searchEnseignement() {

            var apiHttpOptions=SessionToken.getApiHttpOptions();
            if ( apiHttpOptions ){
                let searchEnseignementValue = this.state.selectedEnseignement;
                console.log("Handle['enseignement'].change-> "+searchEnseignementValue);

                if (searchEnseignementValue.length >= 3) {
                    // --------------------------------------------
                    // API['search-ui-enseignement'] => call ...
                    // --------------------------------------------
                    let apiHttpOptions=SessionToken.getApiHttpOptions();
                    if ( apiHttpOptions ){
                        fetch('/rest/private/edition/search-ui-enseignement?annee='+this.state.selectedAnnee+'&input='+searchEnseignementValue, apiHttpOptions)
                            .then((response) => {
                                return response.json();
                            })
                            .then((data) => {
                                if ( data && data.length>0 ){
                                    if ( data.length<=20 ){
                                        this.setState({suggestedEnseignements: data})
                                    } else {
                                        this.setState({suggestedEnseignements: [], alertMsg: "Trop de résultats, veuillez affiner votre recherche"});
                                    }
                                } else {
                                    this.setState({suggestedEnseignements: [], alertMsg: "Aucun enseignement avec ce code et cette année"});
                                }
                            })
                    } else {

                    }
                } else {
                    this.setState({alertMsg: "Il faut saisir au moins 3 lettres"});
                }
            } else {
                this.setState({ isConnected: false });
            }
    }

    handleSelectEnseignement(enseignementId) {

        // creation de header pour les appels connectes
        var apiHttpOptions=SessionToken.getApiHttpOptions();
        if ( apiHttpOptions ){
            console.log("Handle['enseignement'].select -> id='"+enseignementId+"'");
            // --------------------------------------------
            // API['search-ui-enseignement'] => call ...
            // --------------------------------------------
            fetch('/rest/private/edition/search-ui-enseignement?annee='+this.state.selectedAnnee+'&input='+enseignementId, apiHttpOptions)
                .then((response) => {
                    return response.json();
                })
                .then((data) => {
                    if ( data.length===1 ){
                        console.log("API['search-ui-enseignement(annee='"+this.state.selectedAnnee+"',input='"+enseignementId+"')'] -> one-result ["+data.length+"]");
                        console.log("RESULT >> "+JSON.stringify(data));
                        this.setState({selectedEnseignement: enseignementId+' : '+data[0].ens_int, selectedEnseignementId: enseignementId, suggestedEnseignements: []});
                    } else {
                        console.log("API['search-ui-enseignement(annee='"+this.state.selectedAnnee+"',input='"+enseignementId+"')'] -> need-one-result ["+data.length+"]");
                        this.setState({selectedEnseignementId: null, suggestedEnseignements: []});
                    }
                })
        }

    }

    handleNewCodeEnseignement(event){
        if ( event.target.value ){
            console.log("Handle['new-code'].select #0 -> id='"+event.target.value+"'");
            // CODE UE entre 6 et 8 caractere
            if ( event.target.value.length>=6 && event.target.value.length<=8 )
                // value correct
                this.setState({newCodeEnseignement: event.target.value.toUpperCase()});
            else
                // reset
                this.setState({newCodeEnseignement: null});
        }
    }

    handleSubmit(event) {
        let formAnnee   =this.state.selectedAnnee;
        let formEnsId   =this.state.selectedEnseignementId;
        let newCodeEns  =this.state.newCodeEnseignement;

        // creation de header pour les appels connectes
        var apiHttpOptions=SessionToken.getApiHttpOptions();
        if ( apiHttpOptions ){

            if ( formAnnee && formEnsId && newCodeEns ){
                console.log("FormSubmit (annee='"+formAnnee+"',ens_ide='"+formEnsId+"',new_code='"+newCodeEns+"') ...");
                
                // --------------------------------------------
                // API['search-ui-enseignement'] => call ...
                // --------------------------------------------
                fetch('/rest/private/edition/changeCodeEnseignement?annee='+formAnnee+'&enseignement='+formEnsId+'&newEnseignementId='+newCodeEns, apiHttpOptions)
                    .then((response) => {
                        return response.text();
                    })
                    .then((data) => {
                        if ( data.indexOf('true')>=0 ){
                            this.setState({
                                    selectedEnseignement: null,
                                    selectedEnseignementId: null,
                                    suggestedEnseignements: [],
                                    newCodeEnseignement: null,
                                    isLoading: false,
                                    alertType: 'success', alertMsg: 'demande traitée : annee='+formAnnee+' [ "'+formEnsId+'" ~ "'+newCodeEns+'" ] '
                                    
                                });
                        } else {
                            this.setState({
                                    selectedEnseignement: null,
                                    selectedEnseignementId: null,
                                    suggestedEnseignements: [],
                                    newCodeEnseignement: null,
                                    isLoading: false,
                                    alertType: 'danger', alertMsg: 'erreur de traitement : annee='+formAnnee+' [ "'+formEnsId+'" ~ "'+newCodeEns+'" ] '
                                    
                                });
                        }
                    })
                
            } else {
                console.log("FormSubmit (annee='"+formAnnee+"',ens_ide='"+formEnsId+"',new_code='"+newCodeEns+"') -> wrong-submit");
                this.setState({alertMsg: 'demande non traitée',alertType: 'danger'});
            }
        }
        event.preventDefault();
    }

    render() {

        let selectedAnnee = this.state.selectedAnnee;
        let inputEnseignementEnabled=false;
        let inputNouveauCodeEnabled=false;
        let btnSubmitEnabled=false;
        if ( selectedAnnee ){
            inputEnseignementEnabled=true;
        }
        if ( this.state.selectedEnseignementId ){
            inputNouveauCodeEnabled=true;
        }
        if ( this.state.newCodeEnseignement ){
            btnSubmitEnabled=true;
        }

        return (
            <div>
                <div className="title"><span>Administration - Changement de code enseignement</span></div>
                <Row>
                    <Col></Col>
                    <Col xs={8}>
                        { this.state.isLoading ? <FontAwesomeIcon icon={faSpinner}></FontAwesomeIcon> : "" }
                        { this.state.alertMsg ? <Alert variant={this.state.alertType}>{this.state.alertMsg}</Alert> : "" }

                        <Form onSubmit={this.handleSubmit}>
                          <Form.Group controlId="inputAnnee">
                            <Form.Label>Année</Form.Label>
                            <Form.Control as="select"
                                          value={selectedAnnee}
                                          placeholder="..."
                                          onChange={this.handleChangeAnnee}><option value="">...</option>
                                 {this.state.optionsAnnee.map((e, key) => {
                                     return <option key={key} value={e.value}>{e.label}</option>;
                                 })}
                             </Form.Control>
                          </Form.Group>
                          <Form.Group controlId="inputEnseignement">
                            <Form.Label>Enseignement</Form.Label>
                            <Autocomplete
                                inputProps={{ id: 'states-autocomplete', className: "form-control", disabled: !inputEnseignementEnabled }}
                                value={this.state.selectedEnseignement}
                                items={this.state.suggestedEnseignements}
                                getItemValue={ item => item.ens_ide }
                                onChange={this.handleChangeEnseignement}
                                onSelect={ value => this.handleSelectEnseignement(value) }
                                renderMenu={ children => (
                                        <div className = "menu">
                                            { children }
                                        </div>
                                    )}
                                renderItem={ (item, isHighlighted) => (
                                        <div
                                            className={`item ${isHighlighted ? 'item-highlighted' : ''}`}
                                            key={ item.ens_ide+":"+item.ens_int }
                                        >
                                            { item.ens_int } : <small>{item.ens_ide}</small>
                                        </div>
                                    )}
                                wrapperStyle={ {width: '100%'} }
                            />

                         </Form.Group>
                         <Form.Group controlId="inputNouveauCode">
                                <Form.Label>Nouveau code</Form.Label>
                                <Form.Control type="text"
                                              onChange={this.handleNewCodeEnseignement}
                                              disabled={!inputNouveauCodeEnabled}>
                                </Form.Control>
                         </Form.Group>

                         <Button variant="primary" type="submit" disabled={!btnSubmitEnabled}>
                              Changer
                         </Button>

                        </Form>
                    </Col>
                    <Col></Col>
                 </Row>
             </div>
            )
    }
}