import React, {Component} from 'react';
import {Button, ButtonGroup, Col, Container, Dropdown, DropdownButton, Image, Modal, Row} from 'react-bootstrap';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import {faEnvelope, faQuestionCircle, faUpload} from '@fortawesome/free-solid-svg-icons';
import SessionToken from './SessionToken';
import PropTypes from 'prop-types';
import ReactTooltip from "react-tooltip";
import {BallBeat} from "react-pure-loaders";
import ErrorHandler from "./ErrorHandler";


export default class Headers extends Component {
    constructor(props) {
        super(props);
        this.state = {
            annee: '',
            refresh: 0,
            invitGlobale: false,
        }

        this.handleSeDeconnecter = this.handleSeDeconnecter.bind(this);
        this.handleHomeButton = this.handleHomeButton.bind(this);
        this.handleAskInvitationMassive = this.handleAskInvitationMassive.bind(this);
        this.handleAskExportGlobal = this.handleAskExportGlobal.bind(this);
    }

    componentWillMount() {
        fetch('/rest/public/edition/annee')
            .then((response) => {
                return response.text();
            })
            .then((data) => {
                this.setState({annee: data, refresh: this.state.refresh + 1});
            });
        var apiHttpOptions = SessionToken.getApiHttpOptions();
        fetch('/rest/private/edition/config-invit-globale', apiHttpOptions)
            .then((response) => {
                return response.json();
            })
            .then((data) => {
                this.setState({invitGlobale: data.actif});
            });
        fetch('/rest/private/aides/COURS', apiHttpOptions)
            .then((response) => {
                return response.json();
            })
            .then((data) => {
                this.setState({aideCours: data.contenu});
            });
    }

    handleSeDeconnecter() {
        sessionStorage.removeItem('apptoken');
        sessionStorage.removeItem('url');
        this.setState({refresh: this.state.refresh + 1});
        window.location.assign('/error/401');
    }

    handleHomeButton() {
        if (sessionStorage.getItem('url') !== null) {
            sessionStorage.removeItem('url');
            window.location.assign('/auth/client');
        } else {
            window.location.assign('/backend');
        }
    }

    handleAskExportGlobal(event) {
        console.debug("Ask Programme[programme='" + event + "'].exportGlobal");
        let payload = SessionToken.readPayload();
        if (payload && payload.isConnected) {
            this.setState({modalExportGlobal: true, modalExportGlobalConfirm: false, modalDataExportGlobal: null});
        }


    }

    handleExportGlobal(event) {
        console.debug("Programme[programme='" + event + "'].exportGlobal");
        // lancement de l'API
        let payload = SessionToken.readPayload();
        if (payload && payload.isConnected) {
            this.setState({modalExportGlobal: true, modalExportGlobalConfirm: true, modalDataExportGlobal: null});

            // ~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~
            // lancement de l'appel
            let apiHttpOptions = SessionToken.getApiHttpOptions();
            apiHttpOptions.method = 'POST';
            fetch('/rest/private/edition/export-global', apiHttpOptions)
                .then((response) => {
                    if (!response.ok) {
                        console.log("Programme['" + this.props.programme + "'].exportGlobal -> error(" + response.status + ") :: '" + response.statusText + "'");
                        throw Error(response.statusText);
                    }
                    return response;
                })
                .then((response) => {
                    return response.json()
                })
                .then((data) => {
                    // nouveau state pour le MODAL
                    this.setState({modalExportGlobal: true, modalExportGlobalConfirm: true, modalDataExportGlobal: data, refreshList: data.ues});

                    // ~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~
                })
                .catch((error) => {
                    console.debug(error);
                });
            // ~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~
        }
    }

    handleCloseModalExportGlobal() {
        console.debug("Programme['" + this.props.programme + "'].close ...");
        this.setState({modalExportGlobal: false, modalExportGlobalConfirm: false, modalDataExportGlobal: null});
    }

    handleAskInvitationMassive(event) {
        console.debug("Ask Programme[programme='" + event + "'].invitations");
        let payload = SessionToken.readPayload();
        if (payload && payload.isConnected) {
            this.setState({modalInvitation: true, modalInvitationConfirm: false, modalDataInvitations: null, modalAide: false});
        }


    }

    handleInvitationMassive(event) {
        console.debug("Programme[programme='" + event + "'].invitations");
        // lancement de l'API
        let payload = SessionToken.readPayload();
        if (payload && payload.isConnected) {
            this.setState({modalInvitation: true, modalInvitationConfirm: true, modalDataInvitations: null, modalAide: false});

            // ~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~
            // lancement de l'appel
            let apiHttpOptions = SessionToken.getApiHttpOptions();
            fetch('/rest/private/edition/invitation-masse', apiHttpOptions)
                .then((response) => {
                    if (!response.ok) {
                        console.log("Programme['" + this.props.programme + "'].invitations -> error(" + response.status + ") :: '" + response.statusText + "'");
                        throw Error(response.statusText);
                    }
                    return response;
                })
                .then((response) => {
                    return response.json()
                })
                .then((data) => {
                    // nouveau state pour le MODAL
                    this.setState({modalInvitation: true, modalInvitationConfirm: true, modalDataInvitations: data, refreshList: data.invites});

                    // ~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~
                })
                .catch((error) => {
                    console.debug(error);
                });
            // ~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~
        }
    }

    handleCloseModal() {
        console.debug("Programme['" + this.props.programme + "'].close ...");
        this.setState({modalInvitation: false, modalInvitationConfirm: false, modalDataInvitations: null, modalAide: false});
    }

    handleLogoutImpersonate() {
        var apiHttpOptions=SessionToken.getApiHttpOptions();
        if ( apiHttpOptions ) {
            apiHttpOptions.method = 'POST';
            fetch('/rest/private/connected/logout-as', apiHttpOptions)
                .then(ErrorHandler.process)
                .then((data) => {
                    if (data) {
                        SessionToken.renewToken();
                        SessionToken.readPayload();
                        setTimeout(() => {
                            window.location.href = '/';
                        }, 1000);
                    }
                })
            ;
        }
    }

    handleToggleInvitGlobale() {
        var apiHttpOptions = SessionToken.getApiHttpOptions();
        if (apiHttpOptions) {
            apiHttpOptions.method = 'POST';
            fetch('/rest/private/edition/config-invit-globale', apiHttpOptions)
                .then(ErrorHandler.process)
                .then((data) => {
                    this.setState({invitGlobale: data.actif});
                })
            ;
        }
    }

    handleOpenModalAide() {
        this.setState({modalAide: true});
    }

// RENDERS ==================================================================================
    renderModalExportGlobal() {
        if (this.state.modalExportGlobal) {
            return (
                <Modal show={true} aria-labelledby="contained-modal-title-vcenter" size='lg' onHide={() => this.handleCloseModalExportGlobal()}>
                    <Modal.Header closeButton>
                        <Modal.Title>Export de toutes les UE vers ROF</Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                        {this.state.modalExportGlobalConfirm ? "" : <span>Voulez-vous vraiment exporter tous les cours validés vers ROF ?</span>}
                        {this.state.modalDataExportGlobal ?
                            <div>
                                {this.state.modalDataExportGlobal && this.state.modalDataExportGlobal.length > 0 ?
                                    <div>
                                        <p><span className="alert alert-success">Cours exportés vers ROF</span></p>
                                        <ul>
                                            {this.state.modalDataExportGlobal.map( (obj) => <li key={obj.ens_ide}>{ obj.ens_int } ({ obj.ens_ide })</li>)}
                                        </ul>
                                    </div>
                                    :
                                    ""
                                }
                            </div>
                            :
                            <div>
                                {this.state.modalExportGlobalConfirm ? <BallBeat color={'#123abc'} loading={true}/> : ""}
                            </div>
                        }
                    </Modal.Body>
                    <Modal.Footer>
                        {this.state.modalExportGlobalConfirm ? "" : <Button variant="primary" onClick={() => this.handleExportGlobal()}>Confirmer</Button>}
                        <Button variant="secondary" onClick={() => this.handleCloseModalExportGlobal()}>{this.state.modalExportGlobalConfirm ? "Fermer" : "Annuler"}</Button>
                    </Modal.Footer>
                </Modal>
            );
        } else {
            return '';
        }
    }

    renderModalInvitation() {

        if (this.state.modalInvitation) {
            return (
                <Modal show={true} aria-labelledby="contained-modal-title-vcenter" size='lg' onHide={() => this.handleCloseModal()}>
                    <Modal.Header closeButton>
                        <Modal.Title>Invitation générale</Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                        {this.state.modalInvitationConfirm ? "" : <span>Voulez-vous vraiment envoyer une invitation aux enseignants dont les cours ont le statut "à faire" ?</span>}
                        {this.state.modalDataInvitations ?
                            <div>
                                {this.state.modalDataInvitations.noguest && this.state.modalDataInvitations.noguest.length > 0 ?
                                    <div>
                                        <p><span className="alert alert-warning">Cours sans enseignant désigné</span></p>
                                        <ul>
                                            {this.state.modalDataInvitations.noguest.map((obj) => <li key={obj.ens_ide}>{obj.ens_int} ({obj.ens_ide})</li>)}
                                        </ul>
                                    </div>
                                    :
                                    ""
                                }
                                {this.state.modalDataInvitations.nomail && this.state.modalDataInvitations.nomail.length > 0 ?
                                    <div>
                                        <p><span className="alert alert-warning">Cours avec enseignant sans mail</span></p>
                                        <ul>
                                            {this.state.modalDataInvitations.nomail.map((obj) => <li>{obj.ens_int} ({obj.ens_ide})</li>)}
                                        </ul>
                                    </div>
                                    :
                                    ""
                                }
                                {this.state.modalDataInvitations.invites && this.state.modalDataInvitations.invites.length > 0 ?
                                    <div>
                                        <p><span className="alert alert-success">Cours avec enseignant invité</span></p>
                                        <ul>
                                            {this.state.modalDataInvitations.invites.map((obj) => <li>{obj.ens_int} ({obj.ens_ide})</li>)}
                                        </ul>
                                    </div>
                                    :
                                    ""
                                }
                                {this.state.modalDataInvitations.ignores && this.state.modalDataInvitations.ignores.length > 0 ?
                                    <div>
                                        <p><span className="alert alert-dark">Cours ignoré</span></p>
                                        <ul>
                                            {this.state.modalDataInvitations.ignores.map((obj) => <li>{obj.ens_int} ({obj.ens_ide})</li>)}
                                        </ul>
                                    </div>
                                    :
                                    ""
                                }
                            </div>
                            :
                            <div>
                                {this.state.modalInvitationConfirm ? <BallBeat color={'#123abc'} loading={true}/> : ""}
                            </div>
                        }
                    </Modal.Body>
                    <Modal.Footer>
                        {this.state.modalInvitationConfirm ? "" : <Button variant="primary" onClick={() => this.handleInvitationMassive()}>Confirmer</Button>}
                        <Button variant="secondary" onClick={() => this.handleCloseModal()}>{this.state.modalInvitationConfirm ? "Fermer" : "Annuler"}</Button>
                    </Modal.Footer>
                </Modal>
            );
        } else {
            return '';
        }
    }

    renderModalAide() {
        if (this.state.modalAide) {
            return (
                <Modal show={true} aria-labelledby="contained-modal-title-vcenter" size='lg' onHide={() => this.handleCloseModal()}>
                    <Modal.Header closeButton>
                        <Modal.Title>Aide à la rédaction : Liste des cours</Modal.Title>
                    </Modal.Header>
                    <Modal.Body className="height70vh">
                        <div dangerouslySetInnerHTML={{ __html: this.state.aideCours }}></div>
                    </Modal.Body>
                    <Modal.Footer>
                        <Button onClick={() => this.handleCloseModal()}>Fermer</Button>
                    </Modal.Footer>
                </Modal>
            );
        } else {
            return '';
        }
    }

    render() {
        let isConnected = false;
        let isMenu = false;
        let isAdmin = false;
        let isAdminRSP = false;
        let displayName = "Non connecté";
        let payload = SessionToken.readPayload();
        let modalInvitationHtml = this.renderModalInvitation();
        let modalExportGlobalHtml = this.renderModalExportGlobal();
        let modalAideHtml = this.renderModalAide();
        if (payload && payload.isConnected) {
            isConnected = true;
            displayName = payload.login;
            if (payload.displayName) {
                displayName = payload.displayName;
            }
            if (payload.roles.indexOf('api_admin') > 0 || payload.roles.indexOf('rsp') > 0 || payload.roles.indexOf('ass') > 0 || payload.roles.indexOf('ens') > 0 || payload.roles.indexOf('dlg') > 0) {
                isMenu = true;
                if (payload.roles.indexOf('api_admin') > 0) {
                    isAdmin = true;
                }
                if (payload.roles.indexOf('api_admin') > 0 || payload.roles.indexOf('rsp') > 0) {
                    isAdminRSP = true;
                }
            }
        }


        return (
            <header>
                {modalExportGlobalHtml}
                {modalInvitationHtml}
                {modalAideHtml}
                <Container fluid className="container-header-bg">
                    <Row>
                        <Col xs lg="2">
                            <Image src='/DauphineBlanc.png' alt="Logo Dauphine" fluid/>
                        </Col>
                        <Col className="appTitle">
                            {this.props.title} - Année {this.state.annee}
                        </Col>
                        <Col xs className="alignRight">
                            <ButtonGroup>
                                {(isMenu && isAdmin) ?
                                    <div>
                                        <Button size="sm" data-tip data-for='export-global' className="btn btn-ws btn-light btn-lmargin"
                                                onClick={() => this.handleAskExportGlobal()}>
                                            <FontAwesomeIcon icon={faUpload}/> Exporter vers ROF
                                        </Button>
                                        <ReactTooltip id='invitation-programme' type='warning'>
                                            <span>Export global de toutes les UE vers ROF</span>
                                        </ReactTooltip>
                                    </div>
                                    : ""
                                }
                                {(isMenu && (isAdminRSP || isAdmin)) ?
                                    <div>
                                        <Button size="sm" data-tip data-for='invitation-programme' className="btn btn-ws btn-light btn-lmargin"
                                                onClick={() => this.handleAskInvitationMassive()} disabled={!this.state.invitGlobale}>
                                            <FontAwesomeIcon icon={faEnvelope}/> Invitation
                                        </Button>
                                        <ReactTooltip id='invitation-programme' type='warning'>
                                            <span>Invitation aux cours ayant le statut "A faire"</span>
                                        </ReactTooltip>
                                    </div>
                                    : ""
                                }
                                {isMenu && isAdminRSP ?
                                    <span className="cursor-pointer marginleft6 margintop6" data-tip data-for='configInvitGlobale'>
                                        <input type="checkbox" checked={this.state.invitGlobale} name="configInvitGlobale" onChange={() => this.handleToggleInvitGlobale()}/>
                                        <ReactTooltip id='configInvitGlobale' type='warning'>
                                            <span>Activer/Désactiver l'invation en masse</span>
                                        </ReactTooltip>
                                    </span>
                                    : ""
                                }
                                {isMenu ?
                                    <span className="marginleft6">
                                    <Button className="btn-rmargin" variant='light' onClick={() => this.handleHomeButton()} size="sm">Liste des cours</Button>
                                    </span>
                                        : ""
                                }

                                {(isMenu && (isAdminRSP || isAdmin)) ?
                                    <DropdownButton size="sm" className="btn-rmargin" variant="light" id="dropdown-basic-button" title="Admin">
                                        {isAdminRSP ? <Dropdown.Item href="/admin/codeens">Changement de code enseignement</Dropdown.Item> : ''}
                                        {isAdmin ? <Dropdown.Item href="/admin/templatemail">Modèle de mail</Dropdown.Item> : ''}
                                        {isAdminRSP ? <Dropdown.Item href="/admin/datefincampagne">Saisie des paramètres de campagne</Dropdown.Item> : ''}
                                        {isAdminRSP ? <Dropdown.Item href="/admin/extractions">Tableau des extractions</Dropdown.Item> : ''}
                                        {isAdmin ? <Dropdown.Item href="/admin/aides">Aide à la rédaction</Dropdown.Item> : ''}
                                    </DropdownButton>
                                    : ""}

                                {isMenu ?
                                    <div>
                                        <Button size="sm" data-tip data-for='aide-redaction' className="btn btn-ws btn-light"
                                                onClick={() => this.handleOpenModalAide()}>
                                            <FontAwesomeIcon icon={faQuestionCircle}/> Aide
                                        </Button>
                                        <ReactTooltip id='aide-redaction' type='warning'>
                                            <span>Aide à la rédaction</span>
                                        </ReactTooltip>
                                    </div>
                                    : ""
                                }

                                {isConnected && payload.back ?
                                    <span className="marginleft6">
                                    <Button size="sm"
                                            className="btn btn-ws btn-warning"
                                            onClick={() => this.handleLogoutImpersonate()}>
                                        {displayName + ' - retour'}
                                    </Button>
                                    </span>
                                    : ""
                                }
                            </ButtonGroup>
                        </Col>
                        <Col xs lg="2" className="alignRight">
                            {isConnected ?
                                <DropdownButton size="sm" className="btn-rmargin d-inline" variant="primary" id="dropdown-basic-button" title={payload.back ? payload.login : displayName}>
                                    <Dropdown.Item href="/admin/config">Configuration de la fréquence de notifications</Dropdown.Item>
                                    <Dropdown.Item onClick={this.handleSeDeconnecter}>Se déconnecter</Dropdown.Item>
                                </DropdownButton> : ""
                            }

                        </Col>
                    </Row>
                </Container>
            </header>
        );
    }
}


// =================================
// Propriété attendu du composant
// =================================
Headers.propTypes = {
    title: PropTypes.string
};
