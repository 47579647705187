import React, { Component } from 'react';
import { Button, Modal } from 'react-bootstrap';
import Programme from './Programme';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faUpload, faCaretSquareRight, faCaretSquareDown } from '@fortawesome/free-solid-svg-icons';
import ReactTooltip from 'react-tooltip';
import { saveAs } from 'file-saver';

import PropTypes from 'prop-types';
import SessionToken from './../SessionToken';
import { BallBeat } from 'react-pure-loaders';


export default class Mention extends Component {

    constructor(props) {
        super(props);
        this.state={
                resp : false,
                programmes: [],
                selectedProgramme: null,
                selectedProgrammesCollapse: [],
                exportEnMasse: {modalExport: false, modalExportConfirm: false, modalExportResult: null, modalExportEnded:false}
            };

        // Handler
        this.handleMentionClick     =this.handleMentionClick.bind(this);
        this.handleExpandProgramme  =this.handleExpandProgramme.bind(this);
        this.handleCollapseProgramme=this.handleCollapseProgramme.bind(this);
        this.handleAskExportEnMasse=this.handleAskExportEnMasse.bind(this);
        this.handleExportEnMasse=this.handleExportEnMasse.bind(this);
        this.handleExport           =this.handleExport.bind(this);
    }



    // ~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~
    // Récupération au besoin des programmes associés à la Mention encours
    // ~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~
    fetchMention(){
        // Récupération du Header à utiliser
        let apiHttpOptions=SessionToken.getApiHttpOptions();
        let payload = SessionToken.readPayload();
        let programmesServiceString = "";
        if ((payload.roles.indexOf('dlg') > 0 || payload.roles.indexOf('ens') > 0)
            && payload.roles.indexOf('api_admin') === -1
            && payload.roles.indexOf('rsp') === -1) {
            programmesServiceString = '/rest/private/edition/programmes-enseignant?mention=';
        }
        else {
            programmesServiceString = '/rest/private/edition/programmes?mention=';
        }
        // Récupération des données
        if ( apiHttpOptions ){
            fetch(programmesServiceString + this.props.mention, apiHttpOptions)
                .then((response) => {
                    return response.json();
                })
                .then((data) => {
                    this.setState({ programmes: data, resp:SessionToken.isRespMention(this.props.mention) });
                });
        }
    }

    // ~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~
    // Chargement des sous-programmes si EXPAND
    // ~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~
    componentDidMount() {
        setTimeout(function(){
        if ( this.props.expand ){
            this.fetchMention();
        } else {
            this.setState({programmes: [], selectedProgramme: null});
        }
        }.bind(this), 200);
    }


    // HANDLERS =================================================================================
    // Explode "Mention"
    handleMentionClick(event) {
        if ( this.props.expand ){
            this.props.handleCollapseMention(this.props.mention);
            this.setState({selectedProgramme: null});
        } else {
            this.props.handleExpandMention(this.props.mention);
            this.fetchMention();
        }
    }
    
    handleExpandProgramme(cod_prg){
        var array = [...this.state.selectedProgrammesCollapse];
        array.splice(array.indexOf(cod_prg), 1);
        this.setState({selectedProgramme: cod_prg, selectedProgrammesCollapse: array});
    }
    handleCollapseProgramme(cod_prg){
        this.setState({selectedProgramme: null, selectedProgrammesCollapse: [...this.state.selectedProgrammesCollapse, cod_prg]});
    }

    handleAskExportEnMasse(event) {
        if ( this.state.exportEnMasse ){
            if ( this.props.expand && this.state.programmes) {
                let payload = SessionToken.readPayload();
                if (payload && payload.isConnected) {
                    this.setState({exportEnMasse:{modalExport: true, modalExportConfirm: false, modalExportResult: null, modalExportEnded:false}});
                }
            }
        }
    }

    handleExportEnMasse(event) {
        // lancement de l'API
        let payload = SessionToken.readPayload();
        if (payload && payload.isConnected) {
            this.setState({exportEnMasse:{modalExport: true, modalExportConfirm: true, modalExportResult: null, modalExportEnded:false}});

            // ~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~
            // lancement de l'appel
            let apiHttpOptions = SessionToken.getApiHttpOptions();
            apiHttpOptions.method = 'POST';
            fetch('/rest/private/edition/confirmAndExportEnMasse?mention='+this.props.mention, apiHttpOptions)
                .then((response) => {
                    if (!response.ok) {
                        throw Error(response.statusText);
                    }
                    return response;
                })
                .then((response) => {
                    return response.json()
                })
                .then((data) => {
                    // nouveau state pour le MODAL
                    this.setState({exportEnMasse:{modalExport: true, modalExportConfirm: true, modalExportResult: data, modalExportEnded:true}});
                    this.fetchMention();
                    // ~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~
                })
                .catch((error) => {
                    console.debug(error);
                });
            // ~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~
        }
    }

    handleCloseExportEnMasseModal(){
        this.setState({exportEnMasse:{modalExport: false, modalExportConfirm: false, modalExportResult: null, modalExportEnded:false}});
    }


    // RENDERS ==================================================================================

    renderModalExportEnMasse(){

        if ( this.state.exportEnMasse.modalExport ){
            return (
                    <Modal show={true} aria-labelledby="contained-modal-title-vcenter" size='lg' onHide={() => this.handleCloseExportEnMasseModal()}>
                         <Modal.Header closeButton>
                            <Modal.Title>{this.props.libelle} ({this.props.mention})</Modal.Title>
                        </Modal.Header>
                        <Modal.Body>
                            <h3>Export en masse vers ROF</h3><br/>
                            { this.state.exportEnMasse.modalExportConfirm ? "" : <span>Voulez-vous vraiment exporter en masse vers ROF les cours validés ?</span> }
                            { this.state.exportEnMasse.modalExportEnded ?
                                    <div>
                                    <span>Cours exportés vers ROF : </span>
                                    { this.state.exportEnMasse.modalExportResult.length===0 ? "Aucun" :
                                        <ul>
                                            { this.state.exportEnMasse.modalExportResult.map( (obj) => <li key={obj.ens_ide}>{ obj.ens_int } ({ obj.ens_ide })</li> )}
                                        </ul>
                                    }
                                    </div>
                                :
                                    <div>
                                        { this.state.exportEnMasse.modalExportConfirm ? <BallBeat color={'#123abc'} loading={ true } /> : "" }
                                    </div>
                            }
                        </Modal.Body>
                        <Modal.Footer>
                            { this.state.exportEnMasse.modalExportConfirm ? "" : <Button variant="primary" onClick={() => this.handleExportEnMasse(this.props.mention)}>Confirmer</Button> }
                            <Button variant="secondary" onClick={() => this.handleCloseExportEnMasseModal()}>{ this.state.exportEnMasse.modalExportConfirm ? "Fermer" : "Annuler" }</Button>
                        </Modal.Footer>
                    </Modal>
                );
        } else {
            return '';
        }
    }

    handleExport() {
        let selected = this.props.mention;
        var apiHttpOptions=SessionToken.getApiHttpOptions();
        apiHttpOptions.method = 'POST';
        if (apiHttpOptions) {
            fetch('/rest/private/edition/export-excel?mentionCode=' + selected, apiHttpOptions)
                .then((response) => {
                    let data = response.arrayBuffer();
                    return data;
                })
                .then((data) => {
                    var blob = new Blob([data], {type: "application/xlsx"});
                    saveAs(blob, "export.xlsx");
                    this.setState({disableExport: false});
                });
        }
    }

    render(){
        // Programmes
        //    [{"men_annee":"2019/2020","cod_men":"MEM5SES","lib_men":"Master - éco","cod_prg":"PRM4ISES-100","lib_prg":"M1 : 1er année"},...]

        let listProg='';
        if ( this.props.expand && this.state.programmes ){
            let selected=this.state.selectedProgramme;
            let selectedCollapse=this.state.selectedProgrammesCollapse;
            listProg=this.state.programmes.map((item) => 
                    <Programme
                            key={"backend_programme_"+item.cod_prg}
                            annee={this.props.annee} mention={this.props.mention} lib_mention={this.props.libelle}
                            programme={item.cod_prg} libelle={item.lib_prg}
                            expand={ this.props.expand && ((item.cod_prg===selected && selectedCollapse.indexOf(item.cod_prg) === -1) || (this.props.nbEnseignements != null && this.props.nbEnseignements <= 20 && (selectedCollapse.indexOf(item.cod_prg) === -1))) }
                            handleExpandProgramme={this.handleExpandProgramme}
                            handleCollapseProgramme={this.handleCollapseProgramme}
                            refreshList={this.state.exportEnMasse.modalExportResult}
                    />
                );
        }
        let modalExportEnMasseHtml = this.renderModalExportEnMasse();


        return (
                <div>
                    { modalExportEnMasseHtml }
                    <div
                                key={"backend_mentionbar_"+this.props.mention}
                                className="bloc-mention"
                    >
                    <span className="cursor-pointer" onClick={() => this.handleMentionClick(this.props.mention)}>
                        { this.props.expand ? <FontAwesomeIcon icon={faCaretSquareDown} /> : <FontAwesomeIcon icon={faCaretSquareRight} /> }
                        {' '}{ this.props.libelle }
                    </span>
                        { ( this.props.expand && this.state.programmes && this.state.resp) ?
                            <span className="float-right margintopN6">
                                <Button className="btn-rmargin" size="sm" variant="light" data-tip data-for='export-programme' onClick={() => this.handleAskExportEnMasse(this.props.programme)}>
                                    <FontAwesomeIcon icon={faUpload} /> Exporter vers ROF
                                </Button>
                                <ReactTooltip id='export-programme' type='warning'>
                                    <span>Export en masse vers ROF des cours validés</span>
                                </ReactTooltip>
                                <Button className="btn-rmargin" size="sm" variant="light" data-tip data-for='export-excel' onClick={() => this.handleExport()} disabled={this.state.disableExport}>
                                        <FontAwesomeIcon icon={faUpload} /> Extraction Excel
                                </Button>
                                <ReactTooltip id='export-excel' type='warning'>
                                    <span>Export de la mention ouverte au format excel</span>
                                </ReactTooltip>
                            </span>
                            : ""
                        }
                    </div>
                    { this.props.expand ? listProg : "" }
                </div>
            );
    }
}

// =================================
// Propriété attendu du composant
// =================================
Mention.propTypes = {
    annee: PropTypes.string,
    mention: PropTypes.string,
    libelle: PropTypes.string,
    expand: PropTypes.bool,
    handleExpandMention: PropTypes.func,
    handleCollapseMention: PropTypes.func
};
