import React, { Component } from 'react';
import { Table } from 'react-bootstrap';

import Enseignement from './Enseignement';

import PropTypes from 'prop-types';


export default class ComposanteSemestre extends Component {

    constructor(props) {
        super(props);
        this.setState={};
    }

    render(){
        let tableEnseignement="";
        if ( this.props.enseignements ){
            let listEnseignementTr=this.props.enseignements.map((item) => 
                    <Enseignement key={'ens-'+this.props.programme+'-'+item.code}
                            annee={this.props.annee}
                            mention={this.props.mention}
                            programme={this.props.programme}
                            composante={this.props.composante}
                            semestre={this.props.semestre}
                            enseignement={ item.code }
                            libelle={ item.libelle }
                            lib_semestre = { this.props.libelle }
                            lib_mention = { this.props.lib_mention }
                            lib_programme = { this.props.lib_programme }
                            refreshList = { this.props.refreshList }
                        />
                );
            tableEnseignement=(
                    <Table key={'compo-'+this.props.programme+'-sem-'+this.props.semestre} hover className="table_enseignements table_dauphine table_centered table-bordered table_small">
                        <thead>
                            <tr>
                                <th>Enseignement<br/><span className="small">Cliquer sur le libellé d'un cours pour visualiser le descriptif</span></th>
                                <th colSpan="2">Saisi par</th>
                                <th>Statut</th>
                                <th>Actions</th>
                            </tr>
                        </thead>
                        <tbody>
                            { listEnseignementTr }
                        </tbody>
                    </Table>
                );
        }

        return (
                <div className="composante">
                    <div className="bloc-semestre">{this.props.libelle}</div>
                    { tableEnseignement }
                </div>
            );
    }
}

// =================================
// Propriété attendu du composant
// =================================
ComposanteSemestre.propTypes = {
    annee: PropTypes.string,
    mention: PropTypes.string,
    programme: PropTypes.string,
    composante: PropTypes.string,
    semestre: PropTypes.string,
    libelle: PropTypes.string,
    enseignements: PropTypes.array
};