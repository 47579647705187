import React from 'react';
import { Card, Accordion } from 'react-bootstrap';

export default class BodyAdmin extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            contact: 'support.s2rof@dauphine.psl.eu',
        }
    }


    componentWillMount() {
        fetch('/rest/public/edition/contact')
            .then((response) => {
                return response.text();
            })
            .then((data) => {
                this.setState({ contact: data });
            })
    }

    render() {

        return (
            <div>
                <div className="title"><span>Aide à la rédaction</span></div>

                <Card className="card-dauphine">
                    <Card.Header as="h5">
                        <span>Champs Obligatoires</span>
                    </Card.Header>
                    <Card.Body>

                        <Accordion>
                            <Card>
                                <Accordion.Toggle className="cursor-pointer" as={Card.Header} eventKey={1}>
                                    <h6>Langues</h6>
                                </Accordion.Toggle>
                                <Accordion.Collapse eventKey={1}>
                                    <Card.Body>
                                        <ul>
                                            <li><b>Usage</b> : Désigne la ou les langue(s) de l’UE</li>
                                            <li><b>Format</b> : Sélection</li>
                                            <li><b>Gabarit</b> : Liste</li>
                                            <li><b>Contraintes</b> : Sélectionner un élément dans la liste</li>
                                        </ul>
                                    </Card.Body>
                                </Accordion.Collapse>
                            </Card>
                            <Card>
                                <Accordion.Toggle className="cursor-pointer" as={Card.Header} eventKey={2}>
                                    <h6>Compétences à acquérir</h6>
                                </Accordion.Toggle>
                                <Accordion.Collapse eventKey={2}>
                                    <Card.Body>
                                        <ul>
                                            <li><b>Usage</b> : Liste des compétences à acquérir pendant l’UE</li>
                                            <li><b>Format</b> : Texte</li>
                                            <li><b>Gabarit</b> : Zone de texte</li>
                                            <li><b>Contraintes</b> : A présenter sous forme de bullet points, 1 compétence par bullet point, pas de limite de compétences, pas de limite de caractères</li>
                                        </ul>
                                    </Card.Body>
                                </Accordion.Collapse>
                            </Card>
                            <Card>
                                <Accordion.Toggle className="cursor-pointer" as={Card.Header} eventKey={3}>
                                    <h6>Description du contenu de l’enseignement</h6>
                                </Accordion.Toggle>
                                <Accordion.Collapse eventKey={3}>
                                    <Card.Body>
                                        <ul>
                                            <li><b>Usage</b> : Présentation générale de l’unité d’enseignement</li>
                                            <li><b>Format</b> : Texte</li>
                                            <li><b>Gabarit</b> : Zone de texte</li>
                                            <li><b>Contraintes</b> : Texte uniquement, pas de limite de caractères</li>
                                        </ul>
                                    </Card.Body>
                                </Accordion.Collapse>
                            </Card>
                        </Accordion>
                    </Card.Body>
                </Card>

                <Card className="card-dauphine">
                      <Card.Header as="h5">
                          <span>Champs facultatifs</span>
                      </Card.Header>
                      <Card.Body>
                        <Accordion>
                            <Card>
                                    <Accordion.Toggle className="cursor-pointer" as={Card.Header} eventKey={4}>
                                        <h6>Mode de contrôle des connaissances</h6>
                                    </Accordion.Toggle>
                                    <Accordion.Collapse eventKey={4}>
                                        <Card.Body>
                                            <ul>
                                                <li><b>Usage</b> : Présentation du ou des mode(s) de contrôle des connaissances de l’UE</li>
                                                <li><b>Format</b> : Texte</li>
                                                <li><b>Gabarit</b> : Zone de texte</li>
                                                <li><b>Contraintes</b> : Texte uniquement, pas de limite de caractères</li>
                                            </ul>
                                        </Card.Body>
                                    </Accordion.Collapse>
                            </Card>
                            <Card>
                                    <Accordion.Toggle className="cursor-pointer" as={Card.Header} eventKey={5}>
                                        <h6>Prérequis obligatoires</h6>
                                    </Accordion.Toggle>
                                    <Accordion.Collapse eventKey={5}>
                                        <Card.Body>
                                            <ul>
                                                <li><b>Usage</b> : Présentation des prérequis obligatoires</li>
                                                <li><b>Format</b> : Texte</li>
                                                <li><b>Gabarit</b> : Zone de texte</li>
                                                <li><b>Contraintes</b> : Texte uniquement, pas de limite de caractères</li>
                                            </ul>
                                        </Card.Body>
                                    </Accordion.Collapse>
                            </Card>
                            <Card>
                                <Accordion.Toggle className="cursor-pointer" as={Card.Header} eventKey={6}>
                                    <h6>Prérequis recommandés</h6>
                                </Accordion.Toggle>
                                <Accordion.Collapse eventKey={6}>
                                    <Card.Body>
                                        <ul>
                                            <li><b>Usage</b> : Présentation des prérequis recommandés</li>
                                            <li><b>Format</b> : Texte</li>
                                            <li><b>Gabarit</b> : Zone de texte</li>
                                            <li><b>Contraintes</b> : Texte uniquement, pas de limite de caractères</li>
                                        </ul>
                                    </Card.Body>
                                </Accordion.Collapse>
                            </Card>
                            <Card>
                                    <Accordion.Toggle className="cursor-pointer" as={Card.Header} eventKey={7}>
                                        <h6>Bibliographie-lectures recommandées</h6>
                                    </Accordion.Toggle>
                                    <Accordion.Collapse eventKey={7}>
                                        <Card.Body>
                                            <ul>
                                                <li><b>Usage</b> : Présentation des ouvrages à lire pour l’UE</li>
                                                <li><b>Format</b> : Texte</li>
                                                <li><b>Gabarit</b> : Zone de texte</li>
                                            </ul>
                                            <ul>
                                                <li><b>Contraintes</b>: </li>
                                            </ul>
                                            <p>
                                                Texte uniquement, pas de limite de caractères, une ligne par ouvrage, précédée d’un tiret « - »<br/>
                                                Pour chaque ouvrage, préciser son titre, le ou les auteurs, l’édition et/ou l’éditeur et l’année de la publication.
                                            </p>

                                            <ul>
                                                <li><b>Exemple</b>: </li>
                                            </ul>
                                            <p>
                                                - Titre de l’ouvrage 1, Auteur, édition/éditeur (année)<br/>
                                                - Titre de l’ouvrage 2, Auteur 1 et Auteur 2, édition/éditeur (année)
                                            </p>
                                        </Card.Body>
                                    </Accordion.Collapse>
                            </Card>
                            <Card>
                                    <Accordion.Toggle className="cursor-pointer" as={Card.Header} eventKey={8}>
                                        <h6>Niveau de l'enseignement [<small>pour les cours de langues uniquement</small>]</h6>
                                    </Accordion.Toggle>
                                    <Accordion.Collapse eventKey={8}>
                                        <Card.Body>
                                            <ul>
                                                <li><b>Usage</b> : Indique le niveau de langue requis pour suivre un UE de langue.</li>
                                                <li><b>Format</b> : Sélection</li>
                                                <li><b>Gabarit</b> : Liste</li>
                                                <li><b>Contraintes</b> : Sélectionner un élément dans la liste</li>
                                            </ul>
                                        </Card.Body>
                                    </Accordion.Collapse>
                            </Card>
                            <Card>
                                    <Accordion.Toggle className="cursor-pointer" as={Card.Header} eventKey={9}>
                                        <h6>Intervenant(s)</h6>
                                    </Accordion.Toggle>
                                    <Accordion.Collapse eventKey={9}>
                                        <Card.Body>
                                            <ul>
                                                <li><b>Usage</b> : Informatif</li>
                                                <li><b>Format</b> : Sélection</li>
                                                <li><b>Gabarit</b> : Recherche de l’individu dans l’annuaire</li>
                                                <li><b>Contraintes</b> : Pas de contraintes</li>
                                            </ul>
                                        </Card.Body>
                                    </Accordion.Collapse>
                            </Card>
                            <Card>
                                    <Accordion.Toggle className="cursor-pointer" as={Card.Header} eventKey={10}>
                                        <h6>Coefficient</h6>
                                    </Accordion.Toggle>
                                    <Accordion.Collapse eventKey={10}>
                                        <Card.Body>
                                            <ul>
                                                <li><b>Usage</b> : Informatif</li>
                                                <li><b>Format</b> : Texte</li>
                                                <li><b>Gabarit</b> : Zone de texte</li>
                                                <li><b>Contraintes</b> : Pas de contraintes</li>
                                            </ul>
                                        </Card.Body>
                                    </Accordion.Collapse>
                            </Card>
                            <Card>
                                    <Accordion.Toggle className="cursor-pointer" as={Card.Header} eventKey={11}>
                                        <h6>Mots clés</h6>
                                    </Accordion.Toggle>
                                    <Accordion.Collapse eventKey={11}>
                                        <Card.Body>
                                            <ul>
                                                <li><b>Usage</b> : Liste de mots-clés pour le référencement naturel sur le Web</li>
                                                <li><b>Format</b> : Texte</li>
                                                <li><b>Gabarit</b> : Zone de texte</li>
                                                <li><b>Contraintes</b> : pas d’accents, pas de majuscule, pas de caractères spéciaux, séparer les mots pour une virgule.</li>
                                            </ul>
                                            <ul>
                                                <li><b>Exemple</b>: mot 1, mot 2, mot 3, mot 4</li>
                                            </ul>
                                        </Card.Body>
                                    </Accordion.Collapse>
                            </Card>
                        </Accordion>
                    </Card.Body>
                </Card>

                </div>
            )
    }
}