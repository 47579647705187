import React, { Component } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faSpinner, faCaretDown, faMinus } from '@fortawesome/free-solid-svg-icons';
import SessionToken from './../SessionToken';
import ErrorHandler from './../ErrorHandler';
import { Alert, Button, Card, Col, Form, Row, Table, Tabs, Tab } from 'react-bootstrap';
import RichTextEditor from 'react-rte';

export default class BodyTemplateMail extends Component {

    constructor(props) {

        super(props);

        this.state = {
            templateMail: null,
            modeEditionHTML: false,
            templateMails:[],
            params:[],
            showTemplateParams: false,
            isLoading: false,
            alertMsg: null,
            alertType: 'success',
            activeTab: 'list'
        };

        this.handleChangeTemplateMail = this.handleChangeTemplateMail.bind(this);
        this.handleSubmit = this.handleSubmit.bind(this);
        this.changeTab = this.changeTab.bind(this);
        this.handleEditTemplate = this.handleEditTemplate.bind(this);
    }


    retrieveTemplateMails() {
         var apiHttpOptions=SessionToken.getApiHttpOptions();
         if ( apiHttpOptions ) {
             // charge les templates de mail
             fetch('/rest/private/templatemail', apiHttpOptions)
             .then((response) => {
                 return response.json();
             })
             .then((data) => {
                 this.setState({
                     templateMails: data
                 })
             });
         }
    }

     retrieveParamMails() {
         var apiHttpOptions=SessionToken.getApiHttpOptions();
         if ( apiHttpOptions ) {
            // charge les param mails
            fetch('/rest/private/templatemail/params', apiHttpOptions)
            .then((response) => {
                return response.json();
            })
            .then((data) => {
                this.setState({
                    params: data
                })
            })
         }
     }

    componentWillMount() {
        this.retrieveTemplateMails();
        this.retrieveParamMails();
    }

    handleChangeTemplateMail(attribute, event) {
        let fields = this.state;
        if (attribute==='mailrte') {
            fields['templateMail']['mail'] = event;
        } else if (attribute==='mail') {
            fields['templateMail']['mail'] = RichTextEditor.createValueFromString(event.target.value, 'html')
        } else {
            fields['templateMail'][attribute] = event.target.value;
        }
        fields['alertMsg'] = null;
        this.setState({fields});
    }

    handleEditTemplate(templateMail) {
            templateMail.mail = RichTextEditor.createValueFromString(templateMail.mail, 'html')
            this.setState({templateMail : templateMail, activeTab: 'edit'});
    }

    handleSubmit(event) {

        var apiHttpOptions = SessionToken.getApiHttpOptions();
        if ( apiHttpOptions ) {
                apiHttpOptions.method = 'POST';
                apiHttpOptions.headers.append("Content-Type", "application/json");
                var templateMail = this.state.templateMail;
                templateMail.mail=templateMail.mail.toString('html');
                apiHttpOptions.body=JSON.stringify(this.state.templateMail);
                fetch('/rest/private/templatemail/'+this.state.templateMail.code, apiHttpOptions)
                .then(ErrorHandler.process)
                .then(data => {
                     this.changeTab('list', 'Modification réalisée avec succès');
                })
                .catch(error => {
                    templateMail.mail = RichTextEditor.createValueFromString(templateMail.mail, 'html');
                    this.setState({
                        templateMail:templateMail,
                        alertType: 'danger', alertMsg: error.message
                    });
                });

        }
        event.preventDefault();
    }

    renderParams(param) {
     return (
         <tr key={param.code}>
           <td>&#123;&#123;{param.code}&#125;&#125;</td>
           <td>{param.libelle}</td>
           <td>{param.example}</td>
         </tr>
     );
    }

    renderTrTemplateMail(templateMail) {
     return (
         <tr key={templateMail.code}>
           <td>{templateMail.code}</td>
           <td>{templateMail.sujet}</td>
           <td><Button variant="primary"
                       onClick={() => this.handleEditTemplate(templateMail)}>
                    Modifier
               </Button>
           </td>
         </tr>
     );
    }

    renderEditTemplate() {
        if (this.state.templateMail) {
        return (
                <Row>
                    <Col></Col>
                    <Col xs={8}>
                        { this.state.isLoading ? <FontAwesomeIcon icon={faSpinner}></FontAwesomeIcon> : ""}
                        { this.state.alertMsg ? <Alert variant={this.state.alertType}>{this.state.alertMsg}</Alert> : "" }
                        <Form onSubmit={this.handleSubmit}>
                          <Form.Group controlId="code">
                            <Form.Label>Code</Form.Label>
                            <Form.Control type="text"
                                          readOnly={true}
                                          value={this.state.templateMail.code}
                                          onChange={(e) => this.handleChangeTemplateMail("code", e)} />
                          </Form.Group>
                          <Form.Group controlId="sujet">
                            <Form.Label>Sujet</Form.Label>
                            <Form.Control type="text"
                                          value={this.state.templateMail.sujet}
                                          onChange={(e) => this.handleChangeTemplateMail("sujet", e)} />
                          </Form.Group>
                          <Form.Group controlId="mail">
                            <Form.Label>Contenu</Form.Label>
                            { this.state.modeEditionHTML ?
                                    <Form.Control as="textarea" rows="25"
                                                  value={this.state.templateMail.mail.toString('html')}
                                                  onChange={(e) => this.handleChangeTemplateMail("mail", e)} />
                                    :
                                    <RichTextEditor
                                                  value={this.state.templateMail.mail}
                                                  onChange={(e) => this.handleChangeTemplateMail("mailrte", e)}>
                                    </RichTextEditor>
                            }
                          </Form.Group>
                          <Button variant="primary" type="submit">
                              Enregistrer et fermer
                          </Button>{' '}
                          { this.state.modeEditionHTML ? <Button variant="info" onClick={()=>this.setState({modeEditionHTML : false})}>
                                                Edition mode "Texte"
                                              </Button>
                                              :
                                              <Button variant="info" onClick={()=>this.setState({modeEditionHTML : true})}>
                                                Edition mode "HTML"
                                              </Button>
                          }
                          {' '}<Button variant="warning" onClick={()=>this.changeTab('list')}>
                              Fermer sans enregistrer
                          </Button>
                        </Form>
                    </Col>
                    <Col></Col>
                 </Row>
            )
        }
    }

    renderTemplateParams() {
        if (this.state.templateMail) {
            return (
                     <Row>
                        <Col>
                             <Card className="card-dauphine">
                             <Card.Header className="cursor-pointer" onClick={()=>this.setState({showTemplateParams:!this.state.showTemplateParams})}>
                             Liste des champs personnalisés
                             { this.state.showTemplateParams ?
                                <Button className="float-right" size="sm" variant="light"><FontAwesomeIcon icon={faMinus}></FontAwesomeIcon></Button>
                                :
                                <Button className="float-right" size="sm" variant="light"><FontAwesomeIcon icon={faCaretDown}></FontAwesomeIcon></Button>
                             }
                             </Card.Header>
                             { this.state.showTemplateParams ?
                             <Table bordered>
                               <thead>
                                 <tr>
                                   <th>Code</th>
                                   <th>Libellé</th>
                                   <th>Exemple</th>
                                 </tr>
                               </thead>
                               <tbody>
                                 {this.state.params.map(this.renderParams)}
                             </tbody>
                             </Table>
                             :""}
                             </Card>
                         </Col>
                     </Row>
            )
        }
    }

    renderListTemplate() {
        if (!this.state.templateMail) {
            return (
                    <div>
                        {this.state.isLoading ? <FontAwesomeIcon icon={faSpinner}></FontAwesomeIcon> : ""}
                        { this.state.alertMsg ? <Alert variant={this.state.alertType}>{this.state.alertMsg}</Alert> : "" }
                        <Table bordered className="table_dauphine">
                          <thead>
                            <tr>
                              <th>Code</th>
                              <th>Sujet</th>
                              <th>Action</th>
                            </tr>
                          </thead>
                          <tbody>
                            {this.state.templateMails.map((a)=>this.renderTrTemplateMail(a))}
                        </tbody>
                        </Table>
                    </div>
            )
        }
    }

    changeTab(key, message) {
        if (key==='list') {
            this.retrieveTemplateMails();
            this.setState({templateMail:null, activeTab:'list', alertMsg:message})
        }
    }

    renderControlledTabs() {

          return (
            <Tabs
              id="controlled-tab"
              onSelect={(key)=>this.changeTab(key)}
              activeKey={this.state.activeTab}>
              <Tab eventKey="list" title="Liste des modèles de mails">
              </Tab>
              { this.state.templateMail ?
                  <Tab eventKey="edit" title="Modification d'un modèle">
                  </Tab>
                : ""
              }
            </Tabs>
          );
    }

    render() {

        return (
            <div>
                <div className="title"><span>Administration des modèles de mails</span></div>
                { this.renderControlledTabs() }
                { this.renderEditTemplate() }
                { this.renderTemplateParams() }
                { this.renderListTemplate() }
            </div>
        )

    }
}