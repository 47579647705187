import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faSpinner } from '@fortawesome/free-solid-svg-icons';
import { Alert } from 'react-bootstrap';
import ErrorHandler from './../ErrorHandler';


export default class BodyValidation extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            isLoading: false,
            enseignement: undefined,
            validate:false,
            alertMsg: null,
            etat:null,
            alertType: 'danger',
        }

    }

    componentWillMount() {
        var myHeaders = new Headers();
        let apptoken = sessionStorage.getItem('apptoken');
        myHeaders.append("jwt-dauphine", apptoken);
        var myOptions = {
            methode: 'GET',
            headers: myHeaders,
            mode: 'cors',
            cache: 'default'
        };

        this.setState({
            alertMsg: null, isLoading: true
        });
        fetch('/rest/private/edition/enseignement?code=' + this.props.edit_id, myOptions)
            .then(ErrorHandler.process)
            .then((data) => {
                this.setState({
                    enseignement: data.s2EnseignementV,
                    etat: data.etat
                });
                if (data.etat < 4) {
                    this.validate(myOptions);
                } else {
                    this.setState({
                        isLoading: false
                    });
                }
            })
            .catch(error => {
                this.setState({
                    alertType: 'danger', alertMsg: error.message, isLoading: false
                });
            });

    }

    validate(myOptions) {
        // validation du cours
        fetch('/rest/private/edition/validate?enseignement=' + this.props.edit_id, myOptions)
            .then(ErrorHandler.process)
            .then((data) => {
                this.setState({
                    isLoading: false, validate: true
                });
            })
            .catch(error => {
                this.setState({
                    alertType: 'danger', alertMsg: error.message, isLoading: false
                });
            });
    }

    render() {

        return (
            <div>
                <div className="title"><span>Validation de votre enseignement</span></div>
                { this.state.isLoading ? <FontAwesomeIcon icon={faSpinner}></FontAwesomeIcon> : ""}
                { this.state.alertMsg ? <Alert variant={this.state.alertType}>{this.state.alertMsg}</Alert> : "" }
                { this.state.validate ?
                <Alert variant="success">
                <p>Votre enseignement {this.state.enseignement.ens_int} a bien été validé</p>
                <p>Nous vous en remercions</p>

                <p>Pour accéder au descriptif de votre enseignement, <a href={"/edition/"+this.props.edit_id}>cliquez ici</a></p>
                </Alert>
                :""
                }
                { this.state.etat===4 ?
                <Alert variant="info">
                <p>Votre enseignement {this.state.enseignement.ens_int} avait déjà été validé précédemment</p>
                <p>Pour accéder au descriptif de votre enseignement, <a href={"/edition/"+this.props.edit_id}>cliquez ici</a></p>
                </Alert>
                :""
                }
            </div>
        );
    }
}

