import React, { Component } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faSpinner } from '@fortawesome/free-solid-svg-icons';
import { Alert, Button, Col, Form, Row } from 'react-bootstrap';
import SessionToken from './../SessionToken';
import ErrorHandler from './../ErrorHandler';


export default class BodyConfig extends Component {

    constructor(props) {
        super(props);

        this.state = {
            selectedFrequence: null,
            optionsFrequence: [{value:0,label:"Aucune"}, {value:1, label:"Quotidienne"}, {value:7, label:"Hebdomadaire"}],
            isLoading: false,
            alertMsg: null,
            alertType: 'danger'
        };
        this.handleChangeFrequence = this.handleChangeFrequence.bind(this);
        this.handleSubmit = this.handleSubmit.bind(this);

    }

    componentWillMount() {
        // creation de header pour les appels connectes
        var apiHttpOptions=SessionToken.getApiHttpOptions();
        if ( apiHttpOptions ){
            // charge la configuration
            fetch('/rest/private/config', apiHttpOptions)
                .then(ErrorHandler.process)
                .then((data) => {
                    let selectedFrequence = 0;
                    if (data) {
                        selectedFrequence = data.frequence;
                    }
                    this.setState({ selectedFrequence:selectedFrequence, alertMsg: null });
                })
        }
    }


    handleChangeFrequence(event) {
        this.setState({ selectedFrequence: parseInt(event.target.value), alertMsg: null });
    }

    handleSubmit(event) {

        // creation de header pour les appels connectes
        var apiHttpOptions=SessionToken.getApiHttpOptions();
        if ( apiHttpOptions ) {
                apiHttpOptions.method = 'POST';
                apiHttpOptions.headers.append("Content-Type", "application/json");
                var config = {frequence:this.state.selectedFrequence};
                apiHttpOptions.body=JSON.stringify(config);
                fetch('/rest/private/config', apiHttpOptions)
                .then(ErrorHandler.process)
                .then(data => {
                    this.setState({
                        alertType: 'success', alertMsg: 'Configuration mise à jour'
                    });
                })
                .catch(error => {
                    this.setState({
                        alertType: 'danger', alertMsg: error.message
                    });
                });

        }
        event.preventDefault();
    }

    render() {

        return (
            <div>
                <div className="title"><span>Configuration de la fréquence de notifications</span></div>
                { this.state.isLoading ? <FontAwesomeIcon icon={faSpinner}></FontAwesomeIcon> : "" }
                { this.state.alertMsg ? <Alert variant={this.state.alertType}>{this.state.alertMsg}</Alert> : "" }
                { this.state.selectedFrequence != null ?
                <Row>
                    <Col></Col>
                    <Col xs={2}>

                        <Form onSubmit={this.handleSubmit}>
                          <Form.Group controlId="inputFrequence">
                            <Form.Label>Fréquence de notifications</Form.Label>
                            <Form.Control as="select"
                                          value={this.state.selectedFrequence}
                                          onChange={this.handleChangeFrequence}>
                                 {this.state.optionsFrequence.map((e, key) => {
                                     return <option key={key} value={e.value}>{e.label}</option>;
                                 })}
                             </Form.Control>
                          </Form.Group>

                         <Button variant="primary" type="submit">
                              Enregistrer
                         </Button>

                        </Form>
                    </Col>
                    <Col></Col>
                 </Row>
                 : "" }
             </div>
            )
    }
}