import React, {Component} from 'react';
import RichTextEditor from "react-rte";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faCheck, faTimes} from "@fortawesome/free-solid-svg-icons";
import {Button, Form} from "react-bootstrap";
import SessionToken from "../SessionToken";

export default class BodyAide extends React.Component {

    constructor(props) {
        super(props);

        this.state = {
            aideCours: RichTextEditor.createValueFromString('', 'html'),
            aideUe: RichTextEditor.createValueFromString('', 'html'),
        };

        this.getAides = this.getAides.bind(this);
        this.handleChanges = this.handleChanges.bind(this);
        this.handleSave = this.handleSave.bind(this);
        this.handleCancel = this.handleCancel.bind(this);
    }

    componentWillMount() {
        this.getAides();
    }

    getAides() {
        const apiHttpOptions = SessionToken.getApiHttpOptions();
        if (apiHttpOptions) {
            fetch('/rest/private/aides/COURS', apiHttpOptions)
                .then((response) => {
                    return response.json();
                })
                .then((data) => {
                    this.setState({aideCours: RichTextEditor.createValueFromString(data.contenu, 'html')});
                })
            ;
            fetch('/rest/private/aides/UE', apiHttpOptions)
                .then((response) => {
                    return response.json();
                })
                .then((data) => {
                    this.setState({aideUe: RichTextEditor.createValueFromString(data.contenu, 'html')});
                })
            ;
        }
    }

    handleChanges(value) {
        this.setState(value);
    }

    handleSave() {
        const apiHttpOptions = SessionToken.getApiHttpOptions();
        if (apiHttpOptions) {
            apiHttpOptions.method = 'POST';
            apiHttpOptions.headers.append("Content-Type", "application/json");
            apiHttpOptions.body = JSON.stringify([
                {code: 'COURS', contenu :this.state.aideCours.toString('html')},
                {code: 'UE', contenu :this.state.aideUe.toString('html')},
            ]);
            fetch('/rest/private/aides', apiHttpOptions)
                .then((response) => {
                    return response.json();
                })
                .then((data) => {
                    const aideCours = data.find((d) => d.code === 'COURS');
                    const aideUe = data.find((d) => d.code === 'UE');
                    this.setState({aideCours: RichTextEditor.createValueFromString(aideCours.contenu, 'html')});
                    this.setState({aideUe: RichTextEditor.createValueFromString(aideUe.contenu, 'html')});
                })
            ;
        }
    }

    handleCancel() {
        this.getAides();
    }

    render() {
        return (
            <div>
                <div className="title"><span>Aide à la rédaction</span></div>
                <p style={{'marginBottom': '0.5rem'}}>
                    <a href='/'>Accueil (liste des cours)</a>
                </p>
                <form id="form-aides">
                    <div className="row">
                        <div className="col-sm-12 col-md-6">
                            <p className="title_field text-center">Liste des cours</p>
                            <RichTextEditor value={this.state.aideCours}
                                            onChange={(rteValue) => this.handleChanges({ aideCours: rteValue})}
                                            editorClassName="rich-text-editor-max-height500"
                            >
                            </RichTextEditor>
                            <p className="font-info">15 000 caractères maximum</p>
                        </div>
                        <div className="col-sm-12 col-md-6">
                            <p className="title_field text-center">Unité d'enseignement</p>
                            <RichTextEditor value={this.state.aideUe}
                                            onChange={(rteValue) => this.handleChanges({ aideUe: rteValue})}
                                            editorClassName="rich-text-editor-max-height500"
                            >
                            </RichTextEditor>
                            <p className="font-info">15 000 caractères maximum</p>
                        </div>
                    </div>
                    <div className="text-center margintop6">
                        <Button variant="primary" onClick={this.handleSave} className='iconButton'>
                            <FontAwesomeIcon icon={faCheck} size="lg" /> Valider
                        </Button>
                        <Button variant="warning" onClick={this.handleCancel} className='iconButton marginleft6'>
                            <FontAwesomeIcon icon={faTimes} size="lg" /> Annuler
                        </Button>
                    </div>
                </form>
            </div>
        )
    }
}
