import React, {Component} from 'react';
import DatePicker from "react-datepicker";
import {Alert, Button, Col, Form, Row} from 'react-bootstrap';
import SessionToken from './../SessionToken';

import "react-datepicker/dist/react-datepicker.css";

export default class BodyDateFinCampagne extends Component {


    constructor(props) {
        super(props);
        this.state = {
            dateFinCampagneFr: undefined,
            dateDebutCampagneFr: undefined,
            delaiDebutCampagne: 0,
            ignore: false,
            showOk: false
        }

        this.handleChangeDateFin = this.handleChangeDateFin.bind(this);
        this.handleChangeDateDebut = this.handleChangeDateDebut.bind(this);
        this.handleChangeDelai = this.handleChangeDelai.bind(this);
        this.handleChangeIgnore = this.handleChangeIgnore.bind(this);
        // handle submit
        this.handleSubmit = this.handleSubmit.bind(this);
    }

    componentWillMount() {
        // creation de header pour les appels connectes
        var apiHttpOptions = SessionToken.getApiHttpOptions();
        if (apiHttpOptions) {
            fetch('/rest/private/parametres/' + 1, apiHttpOptions)
                .then((response) => {
                    return response.json();
                })
                .then((data) => {
                    this.setState({dateFinCampagneFr: data.dateFinCampagneFr});
                    this.setState({dateDebutCampagneFr: data.dateDebutCampagneFr});
                    this.setState({delaiDebutCampagne: data.delaiDebutCampagne});
                    this.setState({ignore: data.ignore === 'true'});
                });
        }
    }

    handleChangeDateFin(date) {
        this.setState({
            dateFinCampagneFr: date
        });
    }

    handleChangeDateDebut(date) {
        this.setState({
             dateDebutCampagneFr: date
        });
    }

    handleChangeDelai(number) {
        this.setState({
            delaiDebutCampagne: number.target.value
        });
    }

    handleChangeIgnore(check) {
        this.setState({
            ignore: !check.target.checked
        });
    }

    handleSubmit(event) {
        event.preventDefault();
        this.setState({showOk: false});
        let date_options = {year: 'numeric', month: '2-digit', day: '2-digit'};
        var apiHttpOptions = SessionToken.getApiHttpOptions();
        apiHttpOptions.method = 'PUT';
        if (apiHttpOptions) {
            let request = '/rest/private/parametres/update-parametres?';
            if (this.state.dateFinCampagneFr) {
                request += 'dateFin=' + new Date(this.state.dateFinCampagneFr).toLocaleDateString('fr-FR', date_options);
            }
            if (this.state.dateDebutCampagneFr) {
                request += '&dateDebut=' + new Date(this.state.dateDebutCampagneFr).toLocaleDateString('fr-FR', date_options);
            }
            if (this.state.delaiDebutCampagne) {
                request += '&delai=' + this.state.delaiDebutCampagne;
            }
            if (this.state.delaiDebutCampagne) {
                request += '&ignore=' + this.state.ignore;
            }

            fetch(request, apiHttpOptions)
                .then((response) => {
                    this.setState({showOk: true});
                    return response.json();
                })
        }
    }

    render() {
        let dateFin;
        let dateDebut;
        if (this.state.dateFinCampagneFr) {
            dateFin = new Date(this.state.dateFinCampagneFr);
        }
        if (this.state.dateDebutCampagneFr) {
            dateDebut = new Date(this.state.dateDebutCampagneFr);
        }

        return (
            <div>
                <div className="title"><span>Administration - Saisie des paramètres de campagne</span></div>
                <Alert
                    key="Alert_Edit"
                    variant="success"
                    show={this.state.showOk}
                >
                    Les paramêtres ont été modifiés.
                </Alert>
                <Col>
                    <Form onSubmit={this.handleSubmit}>
                        <Row>
                            <Form.Group controlId="dateDebut" className="marginR10p">
                                <Form.Label>Date de début de campagne</Form.Label><br/>
                                <DatePicker dateFormat="dd/MM/yyyy" selected={dateDebut} onChange={this.handleChangeDateDebut}/>
                            </Form.Group>
                            <Form.Group controlId="delai">
                                <Form.Label>Envoi des invitations N jours avant le début de campagne</Form.Label><br/>
                                <input type="number" max="99" value={this.state.delaiDebutCampagne} onChange={this.handleChangeDelai}/>
                                <input className="marginleft6" type="checkbox" checked={!this.state.ignore} onChange={this.handleChangeIgnore}/>
                            </Form.Group>
                        </Row>
                        <Row>
                            <Form.Group controlId="dateFin">
                                <Form.Label>Date de fin de campagne</Form.Label><br/>
                                <DatePicker dateFormat="dd/MM/yyyy" selected={dateFin} onChange={this.handleChangeDateFin}/>
                            </Form.Group>
                        </Row>
                        <Row>
                            <Button variant="primary" type="submit">
                                Enregistrer
                            </Button>
                        </Row>
                    </Form>
                </Col>

            </div>
        )
    }
}